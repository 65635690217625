import {
  EditProviderDetailsPayload,
  FetchProviderListApiResponse,
  FetchProviderListResponse,
  PipelineExecution,
  PipelineExecutionType,
  PipelineSection,
  SupportingDocumentUploadApiResponse,
  SupportingDocumentUploadPayload,
  TerminateProviderPayload,
  UnterminateProviderPayload,
} from '#/types';
import { ProviderFormData } from '../components/ProviderList/ProviderListFilterForm';
import {
  transformProviderDetails,
  transformProvidersQueryParams,
  transformProvidersResponse,
  transformTerminationResponse,
  transformUnterminationResponse,
} from '../data-transformers';
import { pick, pickBy } from 'lodash';
import {
  AddNewProviderPayload,
  CredentialingTimelineFromApi,
  ErrorResponseFromServer,
  ErrorResponseItem,
  FetchFileUploadsResponse,
  ProviderDetails,
  ProviderDetailsAPIResponse,
  UpdateProviderProfilePayload,
  UpdateProviderSubcollectionPayload,
  WorkflowTypes,
} from '../types';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { tagsWithId, tagsWithIdList } from './tags/utils';
import { formatDateForBackend } from '../Utils/date';
import { FlagType } from '../types/flag.types';

export type PaginationQueryParams = {
  limit: number;
  offset?: number;
};

type ProviderDetailsParams = {
  providerId: string;
  version?: number;
  workflowId?: string;
};

type ProviderDetailsByWorkflowParams = {
  providerId?: string;
  workflowId: string;
  workflowType: (typeof WorkflowTypes)[keyof typeof WorkflowTypes];
};

const {
  PROVIDER: TAG_PROVIDER,
  PROVIDER_FILE_UPLOADS,
  PROVIDER_DETAILS,
  MONITORING_WORKFLOW,
  CREDENTIALING_WORKFLOW,
  LICENSING_WORKFLOW_DETAILS,
} = TAGS_MAP;

export const providersApiV2 = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchProviders: builder.query<
      FetchProviderListResponse,
      ProviderFormData | void
    >({
      query: (formData: ProviderFormData) => ({
        url: '/providers',
        method: 'get',
        data: null,
        params: transformProvidersQueryParams(formData!),
      }),
      transformResponse: (response: FetchProviderListApiResponse) =>
        transformProvidersResponse(response),
      providesTags: (result) =>
        tagsWithIdList(TAG_PROVIDER, result?.data || []),
    }),
    fetchProvidersWithFlagType: builder.query<
      FetchProviderListResponse,
      { flagType: FlagType; limit?: number; offset?: number }
    >({
      query: ({ flagType, limit, offset }) => ({
        url: '/providers',
        method: 'get',
        data: null,
        params: { flagType, limit, offset },
        disableSnackbar: true,
      }),
      transformResponse: (response: FetchProviderListApiResponse) =>
        transformProvidersResponse(response),
    }),
    fetchProviderDetails: builder.query<ProviderDetails, ProviderDetailsParams>(
      {
        async queryFn(arg, api, extraOptions, baseQuery) {
          const config = {
            url: `/providers/${arg?.providerId}?includeCredentialingWorkflows=true`,
            method: 'get',
            data: null,
            params: pickBy(pick(arg, ['version'])),
          };
          const timelineConfig = {
            url: arg?.workflowId
              ? `/credentialing-workflows/${arg?.workflowId}/credentialing-timeline`
              : `/providers/${arg?.providerId}/credentialing-timeline`,
            method: 'get',
          };

          const [response, timelineResponse] = await Promise.all([
            baseQuery(config),
            baseQuery(timelineConfig),
          ]);

          if (response.error) {
            throw new Error(
              response.error.data ||
                'Something went wrong while fetching provider details by workflow',
            );
          }

          if (timelineResponse.error) {
            throw new Error(
              timelineResponse.error.data ||
                'Something went wrong while fetching timeline',
            );
          }

          const result = response.data as ProviderDetailsAPIResponse;
          result['credentialingWorkflowTimeline'] =
            timelineResponse.data as CredentialingTimelineFromApi;

          return { data: transformProviderDetails(result) };
        },
        providesTags: (result, error, args) =>
          tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: args?.workflowId }),
      },
    ),

    fetchProviderDetailsByWorkflow: builder.query<
      ProviderDetails,
      ProviderDetailsByWorkflowParams
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const config = {
          url: `/${
            arg?.workflowType === WorkflowTypes.credentialing
              ? 'credentialing-workflows'
              : 'monitoring-workflows'
          }/${arg?.workflowId}/provider`,
          method: 'get',
        };
        const timelineConfig = {
          url:
            arg?.workflowType === WorkflowTypes.credentialing
              ? `/credentialing-workflows/${arg?.workflowId}/credentialing-timeline`
              : `/providers/${arg?.providerId}/credentialing-timeline`,
          method: 'get',
        };

        const [response, timelineResponse] = await Promise.all([
          baseQuery(config),
          baseQuery(timelineConfig),
        ]);

        if (response.error) {
          throw new Error(
            response.error.data ||
              'Something went wrong while fetching provider details by workflow',
          );
        }

        if (timelineResponse.error) {
          throw new Error(
            timelineResponse.error.data ||
              'Something went wrong while fetching timeline',
          );
        }

        const result = response.data as ProviderDetailsAPIResponse;
        result.credentialingWorkflowTimeline =
          timelineResponse.data as CredentialingTimelineFromApi;

        return {
          data: transformProviderDetails(
            result,
            Boolean(arg?.workflowType === WorkflowTypes.monitoring),
          ),
        };
      },
      providesTags: (result, error, args) =>
        tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: args?.workflowId }),
    }),
    createNewProvider: builder.mutation<unknown, AddNewProviderPayload>({
      query: (payload: AddNewProviderPayload) => ({
        url: '/providers',
        method: 'post',
        data: {
          ...payload,
          allProviderStates: payload.allProviderStates.join(','),
          states: payload.allProviderStates,
          lastCredentialedDate: formatDateForBackend(
            payload.lastCredentialedDate,
          ),
          nextCredentialingDate: formatDateForBackend(
            payload.nextCredentialingDate,
          ),
        },
      }),
      invalidatesTags: [TAG_PROVIDER],
    }),
    fetchAllUploads: builder.query<
      FetchFileUploadsResponse,
      PaginationQueryParams | void
    >({
      query: (params: PaginationQueryParams) => ({
        url: '/data-uploads',
        method: 'get',
        params: pickBy(params),
      }),
      providesTags: (result) => {
        return tagsWithIdList(PROVIDER_FILE_UPLOADS, result?.data || []);
      },
    }),
    editProviderDetails: builder.mutation<unknown, EditProviderDetailsPayload>({
      query: (payload: EditProviderDetailsPayload) => ({
        url: `/providers/${payload.providerId}/update-provider-information`,
        method: 'patch',
        data: pick(payload, [
          'firstName',
          'middleName',
          'lastName',
          'providerType',
          'caqhProviderId',
          'npi',
          'reason',
        ]),
      }),
      invalidatesTags: () => [PROVIDER_DETAILS],
    }),
    updateProviderSubcollectionData: builder.mutation<
      unknown,
      UpdateProviderSubcollectionPayload
    >({
      query: (payload: UpdateProviderSubcollectionPayload) => ({
        url: `/providers/${payload.providerId}/${payload.section}/patch-all`,
        method: 'patch',
        data: { list: payload.data, actionSource: payload.actionSource },
      }),
      invalidatesTags: () => [
        TAG_PROVIDER,
        PROVIDER_FILE_UPLOADS,
        PROVIDER_DETAILS,
        MONITORING_WORKFLOW,
        CREDENTIALING_WORKFLOW,
      ],
    }),
    updateProviderProfile: builder.mutation<
      unknown,
      UpdateProviderProfilePayload
    >({
      query: (payload: UpdateProviderProfilePayload) => ({
        url: `/providers/${payload.providerId}`,
        method: 'patch',
        data: payload.data,
      }),
      invalidatesTags: () => [
        TAG_PROVIDER,
        PROVIDER_FILE_UPLOADS,
        PROVIDER_DETAILS,
        MONITORING_WORKFLOW,
        CREDENTIALING_WORKFLOW,
      ],
    }),
    createNewProviderVersion: builder.mutation<unknown, string>({
      query: (providerId: string) => ({
        url: `/providers/new-version/${providerId}`,
        method: 'post',
      }),
      invalidatesTags: () => [
        TAG_PROVIDER,
        PROVIDER_FILE_UPLOADS,
        PROVIDER_DETAILS,
      ],
    }),
    terminateProviders: builder.mutation<
      ErrorResponseItem[] | string,
      TerminateProviderPayload
    >({
      query: (payload) => ({
        url: '/providers/terminate-provider',
        method: 'patch',
        data: {
          providerIds: payload.providerIds,
          reason: payload.reason,
          filters: payload.filters,
        },
      }),
      transformResponse: (response: ErrorResponseFromServer) =>
        transformTerminationResponse(response),
      invalidatesTags: [TAG_PROVIDER],
    }),
    unterminateProviders: builder.mutation<
      ErrorResponseItem[] | string,
      UnterminateProviderPayload
    >({
      query: (payload) => ({
        url: '/providers/unterminate-provider',
        method: 'patch',
        data: {
          providerIds: payload.providerIds,
          reason: payload.reason,
          filters: payload.filters,
        },
      }),
      transformResponse: (response: ErrorResponseFromServer) =>
        transformUnterminationResponse(response),
      invalidatesTags: [TAG_PROVIDER, PROVIDER_DETAILS],
    }),
    triggerPipelineForProvider: builder.mutation<
      void,
      {
        providerId: string;
        pipelineExecutionType?: PipelineExecutionType;
        section?: PipelineSection;
      }
    >({
      query: (data) => ({
        url: `/providers/${data.providerId}/run-pipeline`,
        method: 'post',
        data: {
          pipelineExecutionType: data.pipelineExecutionType,
          section: data.section,
        },
      }),
    }),
    uploadProviders: builder.mutation<unknown, FormData>({
      query: (formData) => {
        return {
          url: '/data-uploads',
          method: 'post',
          data: formData,
        };
      },
      invalidatesTags: () => [TAG_PROVIDER, PROVIDER_FILE_UPLOADS],
    }),
    removeSupportingDocument: builder.mutation<
      unknown,
      {
        providerId: string;
        docId: string;
      }
    >({
      query: ({ providerId, docId }) => {
        return {
          url: `/providers/${providerId}/supporting-documents/${docId}`,
          method: 'delete',
        };
      },
      invalidatesTags: () => [
        TAG_PROVIDER,
        PROVIDER_DETAILS,
        LICENSING_WORKFLOW_DETAILS,
      ],
    }),
    uploadSupportingProviderDocuments: builder.mutation<
      SupportingDocumentUploadApiResponse,
      {
        providerId: string;
        payload: SupportingDocumentUploadPayload;
      }
    >({
      query: ({ providerId, payload }) => {
        const formData = new FormData();
        formData.append('fileType', payload.fileType);
        formData.append('file', payload.file);
        const headers = {
          'Content-Type': 'multipart/form-data',
        };

        return {
          url: `/providers/${providerId}/supporting-documents`,
          method: 'post',
          data: payload,
          headers,
        };
      },
      invalidatesTags: () => [
        TAG_PROVIDER,
        PROVIDER_DETAILS,
        LICENSING_WORKFLOW_DETAILS,
      ],
    }),

    updateProviderCredentialingTimeLine: builder.mutation<
      unknown,
      {
        providerId: string;
        payload: object;
      }
    >({
      query: ({ providerId, payload }) => ({
        url: `/providers/${providerId}/credentialing-timeline`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: () => [TAG_PROVIDER, PROVIDER_DETAILS],
    }),
    fetchLatestPipelineExecution: builder.query<PipelineExecution, string>({
      query: (providerId) => ({
        url: `/providers/${providerId}/pipeline`,
        method: 'get',
      }),
      providesTags: (response) =>
        tagsWithId(TAGS_MAP.PROVIDER_DETAILS, {
          id: response?.workflowExecutionId,
        }),
    }),
    shareProviderFlagsToCredCommittee: builder.mutation<
      unknown,
      { providerId: string; reason: string }
    >({
      query: ({ providerId, reason }) => {
        return {
          url: `/providers/${providerId}/share-to-cred-committee`,
          method: 'patch',
          data: { reason },
        };
      },
      invalidatesTags: () => [TAGS_MAP.FLAGS],
    }),
  }),
});

export const {
  useFetchProvidersQuery,
  useLazyFetchProvidersQuery,
  useFetchProvidersWithFlagTypeQuery,
  useLazyFetchProviderDetailsQuery,
  useFetchProviderDetailsQuery,
  useFetchProviderDetailsByWorkflowQuery,
  useLazyFetchProviderDetailsByWorkflowQuery,
  useCreateNewProviderMutation,
  useEditProviderDetailsMutation,
  useFetchAllUploadsQuery,
  useTerminateProvidersMutation,
  useUnterminateProvidersMutation,
  useUploadProvidersMutation,
  useUpdateProviderSubcollectionDataMutation,
  useUpdateProviderProfileMutation,
  useCreateNewProviderVersionMutation,
  useTriggerPipelineForProviderMutation,
  useRemoveSupportingDocumentMutation,
  useUploadSupportingProviderDocumentsMutation,
  useUpdateProviderCredentialingTimeLineMutation,
  useFetchLatestPipelineExecutionQuery,
  useShareProviderFlagsToCredCommitteeMutation,
  useLazyFetchLatestPipelineExecutionQuery,
} = providersApiV2;
