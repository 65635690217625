import qs from 'qs';
import {
  CreateCredentialingWorkflowLicensingResponse,
  CreateCredentialingWorkflowPayload,
  CreateCredentialingWorkflowResponse,
  CreateLicensingWorkflowPayload,
} from '#/api/credentialing';
import { formatDateFilterPayload, formatToFilterPayload } from '#/api/utils';
import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';
import {
  transformCredentialingWorkflowByIdResponseToDetails,
  transformCredentialingWorkflowListResponse,
  transformFetchAllWorkflowOutreachAttemptsResponse,
} from '#/data-transformers';
import { formatDateForBackend } from '#/Utils/date';

import {
  AnalystData,
  CredentialingWorkflowDetails,
  CredentialingWorkflowListState,
  EditOutreachAttemptPayload,
  FetchCredentialingWorkflowByIdResponse,
  OutreachAttemptPayload,
  OutreachCorrespondenceList,
  UpdateCredentialingWorkflow,
  UpdateCredentialingWorkflowTimeline,
} from '../types';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { tagsWithId } from './tags/utils';

const generateQuery = (
  workflowId: string,
  method: string,
  url: string,
  data?: any,
) => ({
  url: `/credentialing-workflows/${workflowId}${url}`,
  method,
  data,
});

export const credentialingApiV2 = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    cancelWorkflow: builder.mutation<
      void,
      { workflowId: string; reason: string }
    >({
      query: (args) =>
        generateQuery(args.workflowId, 'PUT', '/move-to-cancel', {
          reason: args.reason,
        }),
      invalidatesTags: (result, error, args) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: args.workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: args.workflowId }),
      ],
    }),
    cancelWorkflowBulk: builder.mutation<
      void,
      {
        workflowIds: string[];
        reason: string;
      }
    >({
      query: (args) => {
        return {
          url: `/credentialing-workflows/steps/move-to-cancel/bulk`,
          method: 'PUT',
          data: { ...args },
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.CREDENTIALING_WORKFLOW_List,
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    moveFromInProgressToPsvCompleteByCertifyOs: builder.mutation<void, string>({
      query: (workflowId) =>
        generateQuery(
          workflowId,
          'PUT',
          '/steps/move-from-in-progress-to-psv-complete-by-certifyos',
        ),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
      ],
    }),
    moveFromPsvReadyToPsvCompleteByCertifyOs: builder.mutation<
      void,
      { workflowId: string; reason?: string }
    >({
      query: ({ workflowId, reason }) =>
        generateQuery(
          workflowId,
          'PUT',
          '/steps/move-from-psv-ready-to-psv-complete-by-certifyos',
          { reason },
        ),
      invalidatesTags: (_result, _error, { workflowId }) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
      ],
    }),
    moveFromPsvCompleteByCertifyosToPSVReady: builder.mutation<void, string>({
      query: (workflowId) =>
        generateQuery(
          workflowId,
          'PUT',
          '/steps/move-from-psv-complete-by-certifyos-to-psv-ready',
        ),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
      ],
    }),
    moveFromOutreachInProgressToRecredentialingInProgress: builder.mutation<
      void,
      string
    >({
      query: (workflowId) =>
        generateQuery(
          workflowId,
          'PUT',
          '/steps/move-from-outreach-in-progress-to-recredentialing-in-progress',
        ),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
      ],
    }),
    updateCredentialingPlatformToTabled: builder.mutation<void, string>({
      query: (workflowId) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-tabled'),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToApproved: builder.mutation<
      void,
      { workflowId: string; credStatusDate?: Date; medicalDirectorId?: string }
    >({
      query: ({ workflowId, credStatusDate, medicalDirectorId }) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-cred-approved', {
          credStatusDate: formatDateForBackend(credStatusDate),
          medicalDirectorId,
        }),
      invalidatesTags: (result, error, { workflowId }) => [
        TAGS_MAP.CREDENTIALING_WORKFLOW_List,
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToApprovedBulk: builder.mutation<
      void,
      {
        workflowIds: string[];
        filters: ProviderFormData;
        credStatusDate?: Date;
        medicalDirectorId?: string;
      }
    >({
      query: (args) => {
        if (args.filters) {
          args.filters = formatDateFilterPayload(args.filters);
          formatToFilterPayload(args.filters);
        }
        return {
          url: `/credentialing-workflows/steps/move-to-cred-approved/bulk`,
          method: 'PUT',
          data: {
            ...args,
            credStatusDate: formatDateForBackend(args.credStatusDate),
            medicalDirectorId: args.medicalDirectorId,
          },
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.CREDENTIALING_WORKFLOW_List,
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToDenied: builder.mutation<
      void,
      { workflowId: string; credStatusDate?: Date; medicalDirectorId?: string }
    >({
      query: ({ workflowId, credStatusDate, medicalDirectorId }) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-cred-denied', {
          credStatusDate: formatDateForBackend(credStatusDate),
          medicalDirectorId,
        }),
      invalidatesTags: (result, error, { workflowId }) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToDeniedBulk: builder.mutation<
      void,
      {
        workflowIds: string[];
        filters: ProviderFormData;
        credStatusDate?: Date;
        medicalDirectorId?: string;
      }
    >({
      query: (args) => {
        if (args.filters) {
          args.filters = formatDateFilterPayload(args.filters);
          formatToFilterPayload(args.filters);
        }
        return {
          url: '/credentialing-workflows/steps/move-to-cred-denied/bulk',
          method: 'PUT',
          data: {
            ...args,
            credStatusDate: formatDateForBackend(args.credStatusDate),
            medicalDirectorId: args.medicalDirectorId,
          },
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.CREDENTIALING_WORKFLOW_List,
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToCommittee: builder.mutation<void, string>({
      query: (workflowId) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-committee'),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToCommitteeBulk: builder.mutation<
      void,
      {
        workflowIds: string[];
        filters: ProviderFormData;
      }
    >({
      query: (args) => {
        if (args.filters) {
          args.filters = formatDateFilterPayload(args.filters);
          formatToFilterPayload(args.filters);
        }
        return {
          url: '/credentialing-workflows/steps/move-to-committee/bulk',
          method: 'PUT',
          data: { ...args },
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.CREDENTIALING_WORKFLOW_List,
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToDataMissing: builder.mutation<void, string>({
      query: (workflowId) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-data-missing'),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToOutreachInProgress: builder.mutation<
      void,
      string
    >({
      query: (workflowId) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-outreach-in-progress'),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    updateCredentialingPlatformToInProgress: builder.mutation<void, string>({
      query: (workflowId) =>
        generateQuery(workflowId, 'PUT', '/steps/move-to-in-progress'),
      invalidatesTags: (result, error, workflowId) => [
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
        ...tagsWithId(TAGS_MAP.PROVIDER_DETAILS, { id: workflowId }),
        TAGS_MAP.CREDENTIALING_COMMITTEE,
      ],
    }),
    saveNote: builder.mutation<void, { workflowId: string; message: string }>({
      query: (args) =>
        generateQuery(args.workflowId, 'POST', '/notes', {
          message: args.message,
        }),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: arg.workflowId }),
    }),
    updateNote: builder.mutation<
      void,
      {
        workflowId: string;
        noteId: string;
        message: string;
      }
    >({
      query: (args) =>
        generateQuery(args.workflowId, 'PATCH', `/notes/${args.noteId}`, {
          message: args.message,
        }),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: arg.workflowId }),
    }),
    deleteNote: builder.mutation<
      void,
      {
        workflowId: string;
        noteId: string;
      }
    >({
      query: (args) =>
        generateQuery(args.workflowId, 'DELETE', `/notes/${args.noteId}`),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: arg.workflowId }),
    }),
    updateCredentialingWorkflow: builder.mutation<
      void,
      {
        workflowId: string;
        data: UpdateCredentialingWorkflow;
      }
    >({
      query: (args) => generateQuery(args.workflowId, 'PATCH', '', args.data),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: arg.workflowId }),
    }),
    addOutreachAttempt: builder.mutation<
      void,
      {
        workflowId: string;
        payload: OutreachAttemptPayload;
      }
    >({
      query: ({ workflowId, payload }) => {
        if (
          payload?.method === 'email' &&
          payload?.attachments &&
          payload.attachments.length > 0
        ) {
          const formData = new FormData();

          // Append fields to FormData
          formData.append('method', 'email');
          if (payload.reason && payload.reason.length > 0) {
            payload.reason.forEach((reasonItem, index) => {
              formData.append(`reason[${index}]`, reasonItem);
            });
          }
          if (payload.to && payload.to.length > 0) {
            payload.to.forEach((toItem, index) => {
              formData.append(`to[${index}]`, toItem);
            });
          }
          if (payload.subject) formData.append('subject', payload.subject);
          if (payload.body) formData.append('body', payload.body);
          if (payload.isEmailSaveOnly)
            formData.append(
              'isEmailSaveOnly',
              payload.isEmailSaveOnly.toString(),
            );
          if (payload.attachments && payload.attachments.length > 0) {
            payload.attachments.forEach((file) => {
              formData.append('files', file);
            });
          }

          return {
            url: `/credentialing-workflows/${workflowId}/outreach-attempt`,
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        } else {
          return {
            url: `/credentialing-workflows/${workflowId}/outreach-attempt`,
            method: 'POST',
            body: payload,
          };
        }
      },
      invalidatesTags: (result, error, args) => [
        TAGS_MAP.OUTREACH_CORRESPONDENCE,
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: args.workflowId }),
      ],
    }),
    updateOutreachAttempt: builder.mutation<
      void,
      {
        workflowId: string;
        outreachAttemptId: string;
        payload: EditOutreachAttemptPayload;
      }
    >({
      query: (args) =>
        generateQuery(
          args.workflowId,
          'PUT',
          `/outreach-attempt/${args.outreachAttemptId}`,
          args.payload,
        ),
      invalidatesTags: [TAGS_MAP.OUTREACH_CORRESPONDENCE],
    }),
    markCredOutreachAttemptAsRead: builder.mutation<
      { id: string },
      { workflowId: string; outreachId: string }
    >({
      query: (args) =>
        generateQuery(
          args.workflowId,
          'POST',
          `/outreach-attempt/${args.outreachId}/mark-as-read`,
        ),
      invalidatesTags: [TAGS_MAP.OUTREACH_CORRESPONDENCE],
    }),
    deleteOutreachAttempt: builder.mutation<
      void,
      {
        workflowId: string;
        noteId: string;
      }
    >({
      query: (args) =>
        generateQuery(
          args.workflowId,
          'DELETE',
          `/outreach-attempt/${args.noteId}`,
        ),
      invalidatesTags: (result, error, args) => [
        TAGS_MAP.OUTREACH_CORRESPONDENCE,
        ...tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: args.workflowId }),
      ],
    }),
    fetchCredWorkflowStep: builder.query<CredentialingWorkflowDetails, string>({
      query: (workflowId) => generateQuery(workflowId, 'GET', ''),
      transformResponse: (response: FetchCredentialingWorkflowByIdResponse) => {
        return transformCredentialingWorkflowByIdResponseToDetails(response);
      },
      providesTags: (result, err, workflowId) =>
        tagsWithId(TAGS_MAP.CREDENTIALING_WORKFLOW, { id: workflowId }),
    }),
    fetchOutreachCorrespondenceList: builder.query<
      OutreachCorrespondenceList,
      string
    >({
      query: (workflowId) =>
        generateQuery(workflowId, 'GET', '/outreach-correspondences'),
      providesTags: [TAGS_MAP.OUTREACH_CORRESPONDENCE],
      transformResponse: transformFetchAllWorkflowOutreachAttemptsResponse,
    }),
    generatePsv: builder.mutation<unknown, string>({
      query: (workflowId) => generateQuery(workflowId, 'POST', '/generate-psv'),
      invalidatesTags: [TAGS_MAP.CREDENTIALING_WORKFLOW],
    }),
    fetchAnalysts: builder.query<AnalystData[], void>({
      query: () => ({
        url: '/credentialing-workflows/analysts',
        method: 'GET',
      }),
      providesTags: [TAGS_MAP.CREDENTIAL_ANALYST],
    }),
    fetchMedicalDirectors: builder.query<AnalystData[], void>({
      query: () => ({
        url: '/credentialing-workflows/medical-directors',
        method: 'GET',
      }),
      providesTags: [TAGS_MAP.CREDENTIAL_MEDICAL_DIRECTORS],
    }),
    fetchCredentialingWorkflows: builder.query<
      CredentialingWorkflowListState,
      ProviderFormData
    >({
      query: (params) => {
        let url = `/credentialing-workflows`;
        if (params) {
          const formattedParams = formatDateFilterPayload(params);
          formatToFilterPayload(formattedParams);

          url += `?${qs.stringify(formattedParams, {
            filter: (_, value) => (value ? value : undefined),
          })}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
      transformResponse: transformCredentialingWorkflowListResponse,
      providesTags: (_, __, params) => [
        { type: TAGS_MAP.CREDENTIALING_WORKFLOW_List },
        { type: TAGS_MAP.CREDENTIALING_WORKFLOW_List, ...params },
      ],
    }),
    assignWorkflows: builder.mutation<
      void,
      {
        workflowIds: string[];
        userIds: string[];
        filters: ProviderFormData;
      }
    >({
      query: (args) => {
        if (args.filters) {
          args.filters = formatDateFilterPayload(args.filters);
          formatToFilterPayload(args.filters);
        }
        return {
          url: '/credentialing-workflows/assign-to-analysts',
          method: 'PATCH',
          data: args,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.CREDENTIAL_ANALYST,
        TAGS_MAP.CREDENTIALING_WORKFLOW_List,
      ],
    }),
    updateCredentialingTimeLine: builder.mutation<
      void,
      {
        workflowId: string;
        payload: UpdateCredentialingWorkflowTimeline;
      }
    >({
      query: (args) =>
        generateQuery(
          args.workflowId,
          'PATCH',
          `/credentialing-timeline`,
          args.payload,
        ),
      invalidatesTags: [
        TAGS_MAP.PROVIDER_DETAILS,
        TAGS_MAP.CREDENTIALING_WORKFLOW,
      ],
    }),
    createCredentialingWorkflows: builder.mutation<
      CreateCredentialingWorkflowResponse,
      CreateCredentialingWorkflowPayload
    >({
      query: (args) => ({
        url: '/credentialing-workflows',
        method: 'POST',
        data: args,
      }),
      invalidatesTags: [TAGS_MAP.PROVIDER_DETAILS],
    }),
    createLicensingWorkflows: builder.mutation<
      CreateCredentialingWorkflowLicensingResponse,
      CreateLicensingWorkflowPayload
    >({
      query: (args) => ({
        url: '/licensing',
        method: 'POST',
        data: args,
      }),
      invalidatesTags: [TAGS_MAP.PROVIDER_DETAILS],
    }),
  }),
});

export const {
  useCancelWorkflowMutation,
  useCancelWorkflowBulkMutation,
  useMoveFromInProgressToPsvCompleteByCertifyOsMutation,
  useMoveFromPsvReadyToPsvCompleteByCertifyOsMutation,
  useMoveFromPsvCompleteByCertifyosToPSVReadyMutation,
  useMoveFromOutreachInProgressToRecredentialingInProgressMutation,
  useUpdateCredentialingPlatformToTabledMutation,
  useUpdateCredentialingPlatformToApprovedMutation,
  useUpdateCredentialingPlatformToDeniedMutation,
  useUpdateCredentialingPlatformToCommitteeMutation,
  useUpdateCredentialingPlatformToDataMissingMutation,
  useUpdateCredentialingPlatformToOutreachInProgressMutation,
  useUpdateCredentialingPlatformToInProgressMutation,
  useSaveNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useUpdateCredentialingWorkflowMutation,
  useAddOutreachAttemptMutation,
  useUpdateOutreachAttemptMutation,
  useMarkCredOutreachAttemptAsReadMutation,
  useDeleteOutreachAttemptMutation,
  useFetchCredWorkflowStepQuery,
  useFetchOutreachCorrespondenceListQuery,
  useGeneratePsvMutation,
  useAssignWorkflowsMutation,
  useFetchAnalystsQuery,
  useFetchMedicalDirectorsQuery,
  useFetchCredentialingWorkflowsQuery,
  useLazyFetchCredentialingWorkflowsQuery,
  useUpdateCredentialingTimeLineMutation,
  useCreateCredentialingWorkflowsMutation,
  useCreateLicensingWorkflowsMutation,
  useUpdateCredentialingPlatformToApprovedBulkMutation,
  useUpdateCredentialingPlatformToDeniedBulkMutation,
  useUpdateCredentialingPlatformToCommitteeBulkMutation,
  useLazyFetchCredWorkflowStepQuery,
} = credentialingApiV2;
