import { FetchCredentialingWorkflowsAPIResponse } from '#/api/credentialing';
import {
  CredentialingTimelineFromApi,
  CredentialingWorkflowDetails,
  CredentialingWorkflowListState,
  CredentialingTimeline,
  CredTimelineStep,
  CredWorkflowStep,
  FetchCredentialingWorkflowByIdResponse,
} from '../types';
import { firestoreDateInUtc, apiResponseDateToDate } from '../Utils/date';

export const transformStepForCredTimeline = (
  step: CredWorkflowStep,
): CredTimelineStep => {
  let status: 'in-progress' | 'completed' | 'pending' = 'pending';

  if (step.enteredAt && !step.exitedAt) {
    status = 'in-progress';
  } else if (step.exitedAt) {
    status = 'completed';
  }

  return {
    id: step.id,
    name: step.title,
    seqNo: step.seqNo,
    status,
    createdAt: apiResponseDateToDate(step.createdAt),
    enteredAt: apiResponseDateToDate(step.enteredAt),
    exitedAt: apiResponseDateToDate(step.exitedAt),
  };
};

export const transformStepsForCredTimeline = (
  steps: CredWorkflowStep[],
): CredTimelineStep[] => {
  const updatedSteps = (steps as CredWorkflowStep[])
    .map((step) => transformStepForCredTimeline(step))
    // @ts-ignore
    .sort((a, b) => (a.seqNo < b.seqNo ? -1 : 1));

  return updatedSteps;
};

export const transformCredentialingWorkflowByIdResponseToDetails = (
  data: FetchCredentialingWorkflowByIdResponse,
): CredentialingWorkflowDetails => {
  const notes = !data.notes
    ? []
    : data.notes.map((note) => {
        return {
          ...note,
          createdAt: apiResponseDateToDate(note?.createdAt),
          updatedAt: apiResponseDateToDate(note?.updatedAt),
        };
      });
  return {
    workflowId: data.id,
    providerId: data.providerId,
    organizationId: data.organizationId,
    version: data.version,
    isCompleted: data.isCompleted as boolean,
    onStep: transformStepForCredTimeline(data.onStep),
    psvFile: data.psvFile,
    psvFileSignedUrl: data.psvFileSignedUrl,
    psvChecklist: data.psvChecklist,
    rulesEngineResults: data.rulesEngineResults,
    credCommDetails: data.credCommDetails,
    credentialingCycle: data.credentialingCycle,
    outreachReasons: data.outreachReasons ? data.outreachReasons : [],
    steps: transformStepsForCredTimeline(data.steps as CredWorkflowStep[]),
    totalOutreachAttempts: data.totalOutreachAttempts,
    lastOutreachAttempt: data.lastOutreachAttempt,
    assignment: data.assignment,
    psvFileGenerationErrors: data.psvFileGenerationErrors,
    notes,
  };
};

export const transformCredentialingWorkflowListResponse = (
  response: FetchCredentialingWorkflowsAPIResponse,
): CredentialingWorkflowListState => {
  return {
    count: response.count,
    data: response.data
      ? response.data.map((item) => ({
          id: item.id,
          providerId: item.providerId,
          organizationId: item.organizationId,
          firstName: item?.providerFirstName,
          lastName: item?.providerLastName,
          npi: item.provider?.npi,
          outreachReasons: item?.outreachReasons ? item.outreachReasons : [],
          providerType: item.provider?.providerType,
          providerFileType: item?.provider?.fileType,
          providerStates: item?.providerStates
            ? item?.providerStates.join(', ')
            : '',
          assignedStates: item?.provider?.assignedStates || [],
          providerStatus: item?.provider?.credentialingStatus,
          onStep: item.onStep?.title,
          credCommDetails: item.credCommDetails,
          createdAt: apiResponseDateToDate(item?.createdAt)!,
          hasFlags: item?.provider?.isFlagged,
          credentialingCycle: item.credentialingCycle,
        }))
      : [],
  };
};

export const transformCredentialingWorkflowTimeline = (
  credentialingWorkflowTimelineApiResponse: CredentialingTimelineFromApi,
): CredentialingTimeline => {
  return {
    medicalDirectorName:
      credentialingWorkflowTimelineApiResponse?.medicalDirectorName,
    receivedForCredentialingDate: apiResponseDateToDate(
      credentialingWorkflowTimelineApiResponse?.receivedForCredentialingDate,
    ),
    attestationDate: firestoreDateInUtc(
      credentialingWorkflowTimelineApiResponse?.attestationDate,
    ),
    lastCredentialedDate: apiResponseDateToDate(
      credentialingWorkflowTimelineApiResponse?.lastCredentialedDate,
    ),
    psvCompleteDate: apiResponseDateToDate(
      credentialingWorkflowTimelineApiResponse?.psvCompleteDate,
    ),
    credentialingDecisionDate: firestoreDateInUtc(
      credentialingWorkflowTimelineApiResponse?.credentialingDecisionDate,
    ),
    nextCredentialingDate: apiResponseDateToDate(
      credentialingWorkflowTimelineApiResponse?.nextCredentialingDate,
    ),
    verificationDate: apiResponseDateToDate(
      credentialingWorkflowTimelineApiResponse?.verificationDate,
    ),
    verifierId: credentialingWorkflowTimelineApiResponse?.verifierId,
    id: credentialingWorkflowTimelineApiResponse?.id,
    createdAt: apiResponseDateToDate(
      credentialingWorkflowTimelineApiResponse?.createdAt,
    ),
    createdByName: credentialingWorkflowTimelineApiResponse?.createdByName,
    updatedByName: credentialingWorkflowTimelineApiResponse?.updatedByName,
  };
};
