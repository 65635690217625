import { Grid, Typography } from '@mui/material';

import { Circle as CircleIcon } from '@mui/icons-material';
import { ENotificationType, ExportMetadata } from '#/types';

interface NotificationProps {
  title?: string;
  subtitle?: string;
  description?: string;
  read?: boolean;
  onClick: () => void;
}

export function getTitle(
  title: string,
  type?: string,
  metadata?: ExportMetadata,
) {
  let formattedTitle = title;
  switch (type) {
    // adding providerName to title for psv notifications
    case ENotificationType.PSV_GENERATION:
      if (title && metadata?.providerFirstName && metadata?.providerLastName) {
        formattedTitle = `${title} - ${metadata.providerFirstName} ${metadata.providerLastName}`;
      }
      break;
  }
  return formattedTitle;
}

export const NotificationCard: React.FC<NotificationProps> = ({
  title,
  subtitle,
  description,
  read,
  onClick,
}) => {
  return (
    <Grid
      container
      sx={{ maxWidth: '240px', '&:hover': { cursor: 'pointer' } }}
      onClick={onClick}
    >
      <Grid item xs={1}>
        {!read && (
          <CircleIcon
            sx={{ fontSize: '12px', marginTop: (theme) => theme.spacing(0.6) }}
            color="secondary"
          />
        )}
      </Grid>
      <Grid item xs={11} sx={{ paddingLeft: (theme) => theme.spacing(1.25) }}>
        <Typography
          variant="body1"
          sx={(theme: any) => ({
            fontWeight: !read ? 'bold' : 'normal',
            color: theme.text?.surface?.medium,
          })}
        >
          {title}
        </Typography>
        <Typography variant="overline">{subtitle}</Typography>
        <Typography
          variant="body2"
          sx={(theme: any) => ({
            color: !read
              ? theme.text?.surface?.medium
              : theme.text?.surface?.disabled,
          })}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
