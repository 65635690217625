import {
  CreateEntityLocationPayload,
  CreateEntityProviderPayload,
} from '#/types';

export const affiliationOptions = ['Direct', 'Delegated'];

export const adaComplianceQuestions = [
  {
    title: 'Accessibility to the building: Entrances',
    subTitle:
      'Entrances with ramps or elevators, clear pathways, and appropriate signage to guide individuals with disabilities.',
    name: 'accessibilityToTheBuildingEntrances',
  },
  {
    title: 'Accessibility to the building: Spaces',
    subTitle:
      'The spaces should be wide enough and have an accessible route to the entrance.',
    name: 'accessibilityToTheBuildingSpaces',
  },
  {
    title: 'Reception and waiting areas',
    subTitle:
      'Section at a lower height to accommodate individuals who use wheelchairs. Waiting areas should have accessible seating options and sufficient manoeuvring space for wheelchair users.',
    name: 'receptionAndWaitingAreas',
  },
  {
    title: 'Exam rooms and treatment areas',
    subTitle:
      'At least one exam room should be designed to accommodate individuals with disabilities, including sufficient space for wheelchair manoeuvrability, adjustable examination tables, and accessible medical equipment.',
    name: 'examRoomsAndTreatmentAreas',
  },
  {
    title: 'Restrooms',
    subTitle:
      'Dimensions, grab bars, sinks, and other features to ensure usability for individuals with disabilities.Dimensions, grab bars, sinks, and other features to ensure usability for individuals with disabilities.',
    name: 'restrooms',
  },
  {
    title: 'Signage and wayfinding',
    subTitle:
      'Clear and visible signage to guide individuals with disabilities and help them locate different areas, such as examination rooms, restrooms, and exits.',
    name: 'signageAndWayfinding',
  },
  {
    title: 'Communication accommodations',
    subTitle:
      'Effective communication for individuals with hearing, vision, or speech disabilities. This may include the availability of sign language interpreters, captioning services, or assistive listening devices.',
    name: 'communicationAccomodations',
  },
  {
    title: 'Policies and procedures',
    subTitle:
      'Equal access and non-discrimination for individuals with disabilities',
    name: 'policiesAndProcedures',
  },
];

export const entityLocationTypes = ['Solo', 'Group'];

export const entitySupportingDocuments = [
  { name: 'certificateOfInsurance', label: 'Certificate of Insurance' },
  { name: 'disclosureOfOwnershipForm', label: 'Disclosure Of Ownership Form' },
  { name: 'eftForm', label: 'EFT Form' },
  { name: 'letterOfAppeal', label: 'Letter Of Appeal' },
  { name: 'supplierAgreement460', label: 'Supplier Agreement 460' },
  { name: 'certificateOfGoodStanding', label: 'Certificate of Good Standing' },
  { name: 'fictitousNamePermit', label: 'Fictitous Name Permit' },
  { name: 'bankLetter', label: 'Bank Letter' },
  { name: 'organizationChart', label: 'Organization Chart' },
];

export const entityTaxDocuments = [
  { name: 'irs575', label: 'IRS-575' },
  { name: 'w9', label: 'W-9' },
];

export const addressInitialValues = {
  addressLine1: '',
  zip: '',
  city: '',
  state: '',
  county: '',
  country: 'US',
};

export const defaultLocationValues: CreateEntityLocationPayload = {
  generalInfo: {
    locationName: '',
    mainPhoneNumber: '',
    afterHoursPhoneNumber: '',
    fax: '',
    type: 'Solo',
    suppressSiteFromDirectory: false,
  },
  serviceAddress: addressInitialValues,
  mailingAddress: { ...addressInitialValues, sameAsPrimary: true },
  billingAddress: { ...addressInitialValues, sameAsPrimary: true },
  officeHours: [
    {
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      hourFrom: '09:00',
      hourTo: '17:00',
    },
  ],
  hospitalAdmittingPrivileges: [],
  limitationsForMembers: [],
  adaCompliance: {},
};

export const DAYS_OF_WEEK_OPTIONS = [
  { label: 'Monday', value: 'Mon' },
  { label: 'Tuesday', value: 'Tue' },
  { label: 'Wednesday', value: 'Wed' },
  { label: 'Thursday', value: 'Thu' },
  { label: 'Friday', value: 'Fri' },
  { label: 'Saturday', value: 'Sat' },
  { label: 'Sunday', value: 'Sun' },
];

export const defaultProviderValues: CreateEntityProviderPayload = {
  firstName: '',
  lastName: '',
  providerType: '',
  states: [],
};
