import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MonitoringIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2950_52244)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.56 9.2L23 11.99L21.7869 13.3821C21.3794 12.8437 20.8832 12.3763 20.3201 12.0015L20.33 11.99L19.04 10.52L18.48 9.87L18.56 9.02L18.74 7.07L16.84 6.64L16 6.45L15.56 5.71L14.57 4.03L12.79 4.8L12 5.14L11.2 4.8L9.42 4.03L8.43 5.71L7.99 6.45L7.15 6.64L5.25 7.07L5.43 9.01L5.52 9.87L4.96 10.52L3.67 12L4.96 13.47L5.52 14.12L5.44 14.97L5.26 16.93L7.16 17.36L8 17.55L8.44 18.29L9.43 19.96L11.21 19.19L11.3835 19.1153C11.7229 20.0159 12.2726 20.8134 12.9728 21.4477L12 21.03L8.6 22.5L6.71 19.3L3.1 18.48L3.44 14.78L1 11.99L3.44 9.2L3.1 5.5L6.71 4.69L8.6 1.5L12 2.96L15.4 1.5L17.29 4.69L20.9 5.51L20.56 9.2Z"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5702 9.03003L18.4902 9.88003L19.0402 10.53L20.3302 12L20.3257 12.0051C19.3738 11.3702 18.2302 11 17.0002 11C13.6864 11 11.0002 13.6863 11.0002 17C11.0002 17.741 11.1345 18.4506 11.3801 19.1058L11.2002 19.18L9.42016 19.95L8.43016 18.28L7.99016 17.54L7.15016 17.35L5.25016 16.92L5.43016 14.96L5.51016 14.11L4.95016 13.46L3.66016 11.99L4.95016 10.51L5.51016 9.86003L5.43016 9.01003L5.25016 7.07003L7.15016 6.64003L7.99016 6.45003L8.43016 5.71003L9.42016 4.03003L11.2102 4.80003L12.0002 5.14003L12.7902 4.80003L14.5802 4.04003L15.5702 5.72003L16.0102 6.46003L16.8502 6.65003L18.7502 7.08003L18.5702 9.03003Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.77004 11.4201L10.09 13.7501L15.95 7.87006L17.43 9.36006L15.6402 11.1548C13.4132 11.6708 11.6613 13.4268 11.1511 15.6561L10.09 16.7201L6.29004 12.9101L7.77004 11.4201Z"
        />
        <path d="M20.43 19.02C20.79 18.43 21 17.74 21 17C21 14.79 19.21 13 17 13C14.79 13 13 14.79 13 17C13 19.21 14.79 21 17 21C17.74 21 18.43 20.78 19.02 20.43C19.95 21.36 20.64 22.05 21.59 23L23 21.59C21.5 20.09 22.21 20.79 20.43 19.02ZM17 19C15.9 19 15 18.1 15 17C15 15.9 15.9 15 17 15C18.1 15 19 15.9 19 17C19 18.1 18.1 19 17 19Z" />
      </g>
      <defs>
        <clipPath id="clip0_2950_52244">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
