import {
  FacilityDetails,
  FetchFacilityByIdResponse,
} from '#/types/facility.types';
import { facilityDataCards } from '#/Utils/Constants';
import { firestoreDateInUtc } from '#/Utils/date';

const processSupportingDocsData = (
  data: FetchFacilityByIdResponse,
  cardConfig: any,
) => {
  return {
    // @ts-ignore
    data: (data[cardConfig.id] || []).map((card) => {
      return {
        id: card.id,
        fileType: card.type,
        originalFilename: card.name,
        createdAt: firestoreDateInUtc(card.lastModified),
        fileUrl: card.fileUrl,
      };
    }),
    config: cardConfig,
  };
};

const processDataCard =
  (data: FetchFacilityByIdResponse) => (cardConfig: any) => {
    let cardData: any[] = [];
    if (cardConfig.id === 'facilitySupportingDocuments') {
      return processSupportingDocsData(data, cardConfig);
    }
    if (cardConfig.cardType === 'form') {
      cardData = Object.keys(cardConfig.fields).map((field: string) => {
        // @ts-ignore
        let value = data[field];
        const fieldConfig = cardConfig.fields[field];

        if (fieldConfig.type === 'date') {
          value = firestoreDateInUtc(value);
        }

        return {
          id: cardConfig.id,
          key: field,
          // @ts-ignore
          ...cardConfig.fields[field],
          value,
        };
      });
      // @ts-ignore
    } else if (cardConfig.cardType === 'table' && data[cardConfig.id]) {
      // @ts-ignore
      cardData = data[cardConfig.id].map((row) => {
        return Object.keys(cardConfig.fields).reduce(
          (acc, field) => {
            const fieldConfig = cardConfig.fields[field];
            try {
              if (fieldConfig.type === 'date' && acc[field]) {
                if (acc[field]._seconds) {
                  acc[field] = firestoreDateInUtc(acc[field]);
                } else {
                  acc[field] = new Date(acc[field]);
                }
              }
            } catch (err) {
              console.log('Invalid date ', { field, cardId: cardConfig.id });
            }
            return acc;
          },
          { ...row },
        );
      });
    } else {
      // @ts-ignore
      cardData = data[cardConfig.id];
    }
    return {
      data: cardData,
      config: cardConfig,
    };
  };

export const transformFetchFacilityByIdResponse = (
  data: FetchFacilityByIdResponse,
): FacilityDetails => {
  const processedData: FacilityDetails = {
    id: data.id,
    name: data.name,
    type: data.type,
    status: data.credentialingStatus,
    fileTypeScores: data.fileTypeScores,
    sections: facilityDataCards.map(processDataCard(data)),
  };
  return processedData;
};
