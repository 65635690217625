import React from 'react';
import {
  default as MuiAppbar,
  AppBarProps as MuiAppBarProps,
} from '@mui/material/AppBar';

export interface AppBarProps extends MuiAppBarProps {}

export const Appbar: React.FC<AppBarProps> = (props) => {
  return (
    <MuiAppbar position="static" color="inherit" elevation={0} {...props} />
  );
};

export { default as Toolbar } from '@mui/material/Toolbar';
