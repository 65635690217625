import { HelpOutline } from '@mui/icons-material';
import {
  IconButton,
  Modal,
  Card,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export const HelpFormModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const formData = new URLSearchParams();

      formData.append('name', event.target.name.value);
      formData.append('email', event.target.email.value);
      formData.append('subject', event.target.subject.value);
      formData.append('description', event.target.description.value);
      formData.append('reason', event.target.reason.value);

      const response = await fetch('/api/salesForce', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        enqueueSnackbar('Form submitted successfully!', {
          variant: 'success',
        });
      } else {
        console.error('Form submission failed:', response.statusText);
        enqueueSnackbar('Form submission failed!', { variant: 'error' });
      }
    } catch (error) {
      console.error('Form submission error');
      enqueueSnackbar('Form submission failed!', { variant: 'error' });
    }
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Help button */}
      <IconButton size="large" onClick={handleOpenModal}>
        <HelpOutline />
      </IconButton>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Need Help?
          </Typography>
          <form id="helpForm" onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="reason-label">Request Type</InputLabel>
              <Select
                name="reason"
                // labelId="reason-label"
                label="Request Type"
                variant="outlined"
                fullWidth
              >
                <MenuItem value="">--None--</MenuItem>
                <MenuItem value="Product Bug">Product Bug</MenuItem>
                <MenuItem value="Feature Request">Feature Request</MenuItem>
                <MenuItem value="General Question">General Question</MenuItem>
                <MenuItem value="Billing Issue">Billing Issue</MenuItem>
                <MenuItem value="User Access">User Access</MenuItem>
                <MenuItem value="Service Disruption">
                  Service Disruption
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="subject"
              label="Subject"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </Card>
      </Modal>
    </>
  );
};
