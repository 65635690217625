import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';
import { format } from 'date-fns';
import nookies from 'nookies';

export const formatToFilterPayload = (data: ProviderFormData) => {
  delete Object.assign(data, {
    providerFirstName: data.firstName,
  })['firstName'];
  delete Object.assign(data, {
    providerLastName: data.lastName,
  })['lastName'];
  delete Object.assign(data, {
    providerNpi: data.npi,
  })['npi'];
  delete Object.assign(data, {
    providerFileType: data.fileType,
  })['fileType'];
  delete Object.assign(data, {
    providerState: data.state,
  })['state'];
};

export const formatDateFilterPayload = (data: ProviderFormData) => {
  return Object.entries(data).reduce((acc: any, [key, value]: any) => {
    acc[key] = value;
    if (value instanceof Date) {
      acc[key] = format(value, 'yyyy-MM-dd');
    }
    return acc;
  }, {});
};

export const generateAuthHeader = () => {
  const { auth_token } = nookies.get(undefined);
  return {
    authorization: `Bearer ${auth_token}`,
  };
};
