import { SVGProps } from 'react';
import WhiteLogo from '../styles/assets/icons/WhiteLogo.svg';
import RegisterTick from '../styles/assets/icons/RegisterTick.svg';
import Logo from '../styles/assets/icons/Logo.svg';

export const CertifyOsWhiteLogo = (props: SVGProps<SVGElement>) => (
  <WhiteLogo {...props} />
);
export const CertifyOsLogo = (props: SVGProps<SVGElement>) => (
  <Logo {...props} />
);
export const RegisterTickIcon = (props: SVGProps<SVGElement>) => (
  <RegisterTick {...props} />
);
