import React, { useMemo } from 'react';
import { Box, Toolbar, styled, ThemeProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import flagsmith, { createFlagsmithInstance } from 'flagsmith/isomorphic';
import { IState } from 'flagsmith/types';
import { FlagsmithProvider } from 'flagsmith/react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Sidebar } from '#/components/Sidebar';
import { Topbar } from '#/components/Topbar';
import { fetchLoggedInUser } from '#/redux/actions/users';
import { AppDispatch, AppState } from '#/redux/reducers/rootReducer';
import { persistor, store, wrapper } from '#/redux/store';
import { SnackbarUtilsConfigurator } from '#/Utils/SnackbarUtils';
import { appTheme } from '#/Utils/theme';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/globals.scss';
import Head from 'next/head';
import { addAxiosInterceptors } from '../Utils/httpService';
import { Logout, checkTokenAction } from '../redux/actions/Auth/Auth';
import { useLazyFetchNotificationsQuery } from '#/services';
import nookies from 'nookies';

import { useSession } from '../hooks/useSession';
import { allOrgsValue } from '#/Utils/Constants';
import { hideAllHelpBanners } from '#/redux/actions/helpBanners';

const flagsmithEnvId = process.env.NEXT_PUBLIC_FLAGSMITH_CLIENT_KEY;

if (!!process.env.NEXT_PUBLIC_MUI_PRO_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PRO_LICENSE_KEY);
}
if (process.env.NODE_ENV === 'test') {
  require('../mock-server');
}
const Page = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  display: 'flex',
  maxWidth: '100vw',
}));
const MainContent = styled(Box)(() => ({
  flexGrow: 1,
  p: 3,
}));

const unauthRoutes = [
  '/login',
  '/signup',
  '/forgot-password',
  '/outreach-uploads',
];

type CustomProps = AppProps & {
  flagsmithState: IState;
};

function MyApp({ Component, pageProps, flagsmithState }: CustomProps) {
  const router = useRouter();
  const { store: wrappedStore } = wrapper.useWrappedStore(store);
  const dispatch: AppDispatch = useDispatch();
  const loginState = useSelector((state: AppState) => state.auth);
  const session = useSession();
  useEffect(() => {
    return addAxiosInterceptors();
  }, []);
  useEffect(() => {
    if (session.loggedIn && !loginState?.token) {
      checkTokenAction()(dispatch);
    } else if (!session.loading && !session.loggedIn && loginState.token) {
      dispatch(Logout());
    }
  }, [session, dispatch, loginState?.token]);

  const sidebarVisible = useMemo(
    () =>
      router.pathname !== '/business-requests' &&
      router.pathname !== '/import-providers' &&
      !!loginState?.token &&
      !!session.loggedIn,
    [router.pathname, loginState?.token, session.loggedIn],
  );
  const loggedInUser = useSelector((state: AppState) => state.loggedInUser);
  const [fetchNotifications] = useLazyFetchNotificationsQuery();

  const { organizationId, email } = useMemo<{
    organizationId?: string;
    email?: string;
  }>(
    () => ({
      email: loggedInUser?.data?.email,
      organizationId: loggedInUser?.selectedOrgId,
    }),
    [loggedInUser?.data?.email, loggedInUser?.selectedOrgId],
  );
  useEffect(() => {
    if (email && !session.loading && organizationId) {
      flagsmith.identify(email, {
        organizationId: organizationId || '',
        email,
      });
    }
  }, [email, session, organizationId, loggedInUser, router]);

  useEffect(() => {
    if (router.isReady && loginState?.token && session.loggedIn) {
      console.log('fetch logged in user');
      dispatch(hideAllHelpBanners());
      dispatch(fetchLoggedInUser());
      if (
        loggedInUser?.selectedOrgId &&
        loggedInUser?.selectedOrgId !== allOrgsValue
      ) {
        fetchNotifications();
      }
    }
  }, [
    loginState?.token,
    dispatch,
    fetchNotifications,
    router.isReady,
    session.loggedIn,
  ]);
  const children = useMemo(() => {
    if (unauthRoutes.includes(router.pathname)) {
      return <Component {...pageProps} />;
    } else {
      return (
        <Page>
          <CssBaseline />
          <Topbar isLoggedIn={!!loginState?.token && !!session.loggedIn} />
          {sidebarVisible && <Sidebar />}
          <MainContent>
            <Toolbar />
            <Component {...pageProps} />
          </MainContent>
        </Page>
      );
    }
  }, [
    router.pathname,
    Component,
    pageProps,
    loginState?.token,
    session.loggedIn,
    sidebarVisible,
  ]);

  return (
    <>
      <Head>
        <title>CertifyOS</title>
      </Head>

      <Provider store={wrappedStore}>
        <FlagsmithProvider
          serverState={flagsmithState}
          options={{ environmentID: flagsmithEnvId || '' }}
          flagsmith={flagsmith}
        >
          <ThemeProvider theme={appTheme}>
            <PersistGate persistor={persistor}>
              <SnackbarProvider
                maxSnack={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <SnackbarUtilsConfigurator />

                {children}
              </SnackbarProvider>
            </PersistGate>
          </ThemeProvider>
        </FlagsmithProvider>
      </Provider>
    </>
  );
}

MyApp.getInitialProps = async ({ ctx }: any) => {
  if (ctx && ctx.req) {
    const cookies = nookies.get(ctx);
    const flagsmithSSR = createFlagsmithInstance();
    let email;
    if (cookies.flagIdentifier) {
      email = Buffer.from(cookies.flagIdentifier, 'base64').toString('ascii');
    }
    if (flagsmithEnvId && email) {
      await flagsmithSSR.init({
        environmentID: flagsmithEnvId,
        identity: email,
      });
      return { flagsmithState: flagsmithSSR.getState() };
    }
  } else {
    if (flagsmithEnvId) {
      await flagsmith.init({ environmentID: flagsmithEnvId });
      return {
        flagsmithState: flagsmith.getState(),
      };
    }
  }

  return {
    flagsmithState: null,
  };
};

export default wrapper.withRedux(MyApp);
