import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import MailBoxImg from './mailbox.svg';

export const EmptyNotificationsView: React.FC = () => {
  return (
    <Box
      sx={{ maxWidth: '240px', height: '100%' }}
      rowGap={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <MailBoxImg />
      <Typography variant="h6">No notifications yet</Typography>
    </Box>
  );
};
