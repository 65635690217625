import { ApiResponseDateTime } from '#/Utils/types';

export interface ExportMetadata {
  jobId: string;
  file?: string;
  fileName?: string;
  providerFirstName?: string;
  providerLastName?: string;
  npi?: string;
  providerId?: string;
  credentialingWorkflowId?: string;
  facilityWorkflowId?: string;
  workflowId?: string;
}

export enum ENotificationType {
  EXPORT = 'export',
  CREDENTIALING_EXPORT = 'credentialingExport',
  MONITORING_EXPORT = 'monitoringExport',
  PROVIDER_EXPORT = 'providerExport',
  PSV_GENERATION = 'psvGeneration',
  FACILITY_PSV_GENERATION = 'facilityPsvGeneration',
  EXPORT_WORKFLOW = 'exportWorkflow',
  VALIDATE_WORKFLOW = 'validateWorkflow',
  VALIDATE_ROSTER = 'validateRoster',
  VALIDATE_ROSTER_PARTIAL = 'validateRosterPartial',
  LICENSING_REQUEST_CREATED = 'licensingRequestCreated',
  LICENSING_WORKFLOW_ASSIGNED = 'licensingWorkflowAssigned',
}
export interface Notification {
  urgency: string;
  organizationId: string;
  title: string;
  text: string;
  read?: boolean;
  id: string;
  userId: string;
  metadata: ExportMetadata;
  type: ENotificationType;
  createdAt: ApiResponseDateTime;
}
