import {
  FacilityCredWorkflowDetailsApiResponse,
  FacilityWorkflowDetails,
} from '#/types/facility-credentialing-workflows.types';
import { transformStepsForCredTimeline } from './credentialing.transformer';
import { transformFetchFacilityByIdResponse } from './facility.transformer';

export const transformFetchFacilityCredWorkflowDetailsResponse = (
  data: FacilityCredWorkflowDetailsApiResponse,
): FacilityWorkflowDetails | any => {
  const transformedFacilityDetails = transformFetchFacilityByIdResponse(
    data.facilityDetails,
  );
  const transformedSteps = transformStepsForCredTimeline(data.steps);

  const response: FacilityWorkflowDetails = {
    ...data,
    facilityDetails: transformedFacilityDetails,
    steps: transformedSteps,
  };

  return response;
};
