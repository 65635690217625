import { CredentialingWorkflowStepsState } from '#/redux/reducers/credentialingWorkflowStepsReducer';
import { CredentialingStatusType, Flag, ProviderDetails } from '#/types';
import { DataCardConfig } from '#/types/data-card-config.types';
import { isEmpty } from 'lodash';
import {
  CredSteps,
  CredStepsDisplayLabel,
  ShowActionCredSteps,
  StatusActions,
} from './Constants';

export const getInitialsFromString = (text: string) => {
  if (!text) return null;
  const parts = text.split(' ');
  return parts
    .map((part) => {
      if (!part.trim()) return '';
      return part.trim()[0].toUpperCase();
    })
    .join('');
};

export const hasRoles = (userRoles: string[], requiredRoles: string[]) => {
  if (requiredRoles.length == 0) return true;
  return requiredRoles.reduce((result: boolean | undefined, role: string) => {
    if (result === true) return true;
    return userRoles && userRoles.includes(role);
  }, false);
};

export const isActionAudited = (currentStatus: string, updatedStatus: string) =>
  (ShowActionCredSteps.includes(updatedStatus) &&
    currentStatus != updatedStatus) ||
  (updatedStatus === CredSteps.PSV_COMPLETE_BY_CERTIFYOS &&
    currentStatus === CredSteps.PSV_READY);

export const getWorkflowStatusAction = (
  currentStatus: string,
  updatedStatus: string,
) => {
  if (updatedStatus === CredSteps.PSV_READY)
    return StatusActions.MOVE_TO_PSV_READY;
  else if (updatedStatus === CredSteps.PROVIDER_TERMINATED)
    return StatusActions.TERMINATE;
  else if (
    updatedStatus === CredSteps.PSV_COMPLETE_BY_CERTIFYOS &&
    currentStatus === CredSteps.PSV_READY
  )
    return StatusActions.MOVE_TO_PSV_COMPLETE_BY_CERTIFYOS;
  else return StatusActions.WITHDRAW_CANCELED;
};

export const getCardSectionFlagFields = (cardConfig: DataCardConfig) => {
  return Object.keys(cardConfig.fields || {})
    .map((fieldId: string) => {
      const fieldConfig = (cardConfig.fields || {})[fieldId];
      return {
        id: fieldId,
        config: fieldConfig,
      };
    })
    .filter((field: any) => {
      return field.config.type === 'chip';
    });
};

export interface ProviderFlagsData {
  flag: Flag;
  cardDetails: {
    [field: string]: any;
  };
  cardConfig: DataCardConfig;
}

export const extractProviderFlagData = (
  providerDetails: ProviderDetails,
  config: DataCardConfig[],
): ProviderFlagsData[] => {
  return config
    .map((cardConfig) => {
      // @ts-ignore
      const dataRows = providerDetails[cardConfig.id];
      const flagFields = getCardSectionFlagFields(cardConfig);
      return dataRows
        .map((data: any) => {
          return flagFields
            .filter((fieldConfig) => !!data[fieldConfig.id])
            .map((fieldConfig) => {
              return {
                flag: data[fieldConfig.id],
                cardDetails: data,
                cardConfig,
              };
            });
        })
        .flat();
    })
    .flat();
};

export const checkIfFlagsAreSynced =
  (cardId: string, flagFields: any[], data: any) =>
  (_result: any): boolean => {
    // @ts-ignore
    const cardDataRows: any[] = data && data[cardId];
    const allFlagsSynced = flagFields.reduce((acc: boolean, field: any) => {
      if (!acc) return acc;
      let hasFlag = false;
      let hasFlagSynced = false;
      cardDataRows.forEach((row: any) => {
        const fieldData = row[field.id];
        if (!isEmpty(fieldData)) {
          hasFlag = true;
          hasFlagSynced = fieldData.synced;
        }
      });
      if (hasFlag) {
        acc = !!hasFlagSynced;
      }
      return acc;
    }, true);
    return !allFlagsSynced;
  };

const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
// polls a function until condition is met (i.e, until fnCondition function returns true)
export const poll = async function (
  fn: () => Promise<any>,
  fnCondition: (result: any) => boolean,
  ms = 10000,
  maxCalls = 10,
) {
  console.log('polling for new data...');
  let result = await fn();
  let counter = 0;
  while (counter <= maxCalls && fnCondition(result)) {
    counter++;
    await wait(ms);
    result = await fn();
  }
  return result;
};

export const stringCompareFn = (v1: string, v2: string) => {
  return v1.localeCompare(v2);
};

export const unflatten = (data: any) => {
  const result = {};
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce(function (v1: any, v2: any, j) {
      return (
        v1[v2] ||
        (v1[v2] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 == j
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

export const getCredStepDisplayLabel = (value: string): string => {
  return value ? CredStepsDisplayLabel[value] ?? value : '';
};
