import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { NotificationCard, getTitle } from '../NotificationCard';
import { EmptyNotificationsView } from './EmptyNotificationsView';
import { useMarkAsReadMutation } from '#/services';
import { apiResponseDateToDate } from '#/Utils/date';
import { ENotificationType, Notification } from '#/types';
import { useLazyFetchJobQuery } from '#/services/jobs.service';
import { downloadFileFromUrl } from '#/Utils/download';
import { DownloadNotificationTypes } from '#/Utils/Constants';
import { useRouter } from 'next/router';

export interface NotificationDrawerProps {
  open: boolean;
  onClose: () => void;
  items: Notification[];
  renderActions?: (
    item: Notification,
    { markAsRead }: { markAsRead: () => void },
  ) => JSX.Element;
}

export const NotificationDrawer: React.FC<NotificationDrawerProps> = ({
  open,
  onClose,
  items,
  renderActions,
}) => {
  const router = useRouter();
  const divider = <Divider variant="middle" sx={{ margin: '8px 0' }} />;

  const [fetchJobData] = useLazyFetchJobQuery();
  const [markAsRead] = useMarkAsReadMutation();

  const handleNotificationClick = async (notificationData: Notification) => {
    if (notificationData.type === ENotificationType.PSV_GENERATION) {
      router.push(
        `/credentialing/${notificationData.metadata.credentialingWorkflowId}`,
      );
      return;
    }
    if (notificationData.type === ENotificationType.FACILITY_PSV_GENERATION) {
      router.push(
        `/facility-credentialing-workflows/${notificationData.metadata.facilityWorkflowId}`,
      );
      return;
    }
    if (
      notificationData.type === ENotificationType.VALIDATE_ROSTER_PARTIAL ||
      notificationData.type === ENotificationType.VALIDATE_ROSTER
    ) {
      router.push(
        notificationData.metadata?.fileName
          ? `/rosteros?fileName=${notificationData.metadata?.fileName}`
          : '/rosteros',
      );
      return;
    }
    if (DownloadNotificationTypes.includes(notificationData.type)) {
      const jobData = await fetchJobData(notificationData.metadata.jobId);
      const filePath = jobData.data?.filePath;
      if (filePath) {
        downloadFileFromUrl(
          filePath,
          filePath.substring(filePath.lastIndexOf('/') + 1),
        );
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={0}
      PaperProps={{
        sx: (theme) => ({
          top: theme.spacing(8),
          right: '0',
          padding: theme.spacing(3),
          height: `calc(100% - ${theme.spacing(7.5)})`,
        }),
        role: 'list',
        'aria-label': 'notifications',
      }}
      BackdropProps={{
        style: {
          position: 'absolute',
          background: 'rgba(0, 0, 0, 0.1)',
        },
      }}
      ModalProps={{
        sx: (theme) => ({
          top: theme.spacing(8),
        }),
      }}
    >
      <Typography variant="h6">Notifications</Typography>
      {!items?.length && <EmptyNotificationsView />}
      <List>
        {!!items?.length &&
          items.map((item, i) => {
            const createdAt = apiResponseDateToDate(item.createdAt) || '';
            const notifItem = (
              <ListItem key={item.id}>
                <Stack>
                  <Box>
                    <NotificationCard
                      title={getTitle(item.title, item.type, item.metadata)}
                      subtitle={`${
                        createdAt ? format(createdAt, 'MM/dd/yyyy hh:mm a') : ''
                      }`}
                      description={item.text}
                      read={item.read || false}
                      onClick={() => {
                        markAsRead(item.id);
                        handleNotificationClick(item);
                      }}
                    />
                  </Box>
                  {renderActions?.(item, {
                    markAsRead: () => {
                      markAsRead(item.id);
                    },
                  })}
                </Stack>
              </ListItem>
            );
            if (i === items.length - 1) {
              return notifItem;
            }
            return (
              <React.Fragment key={item.id}>
                {notifItem}
                {divider}
              </React.Fragment>
            );
          })}
      </List>
    </Drawer>
  );
};
