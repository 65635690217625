import { styled } from '@mui/material/styles';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';

export interface IconButtonProps extends MuiIconButtonProps {
  selected?: boolean;
}

export const IconButton = styled(MuiIconButton)<IconButtonProps>(
  ({ theme, selected }) => ({
    '&,&:focus,&:active,&:hover': {
      backgroundColor: selected && theme.palette.primary.main,
      color: selected && '#fff',
    },
  }),
);
