import qs from 'qs';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import {
  ExpiringLicensesQueryParams,
  ExpiringLicensesResponse,
  TasksDashboardOverview,
  LicensingWorkflowAssigneesResponse,
  LicensingWorkflowOutreachResponse,
  LicensingWorkflowParams,
  LicensingWorkflowResponse,
  NewLicensingWorflowResponse,
  Task,
  TasksQueryParams,
} from '#/types/licensing-workflows.types';
import { generateAuthHeader } from '#/api/utils';
import { isEqual, pickBy } from 'lodash';
import { tagsWithId } from './tags/utils';
import { transformLicensingWorkflowDetails } from '#/data-transformers/licensing.transformer';
import { formatDate } from '#/Utils/date';

export const licensingApi = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchLicensingWorkflows: builder.query<
      {
        workflows: LicensingWorkflowResponse[];
        count: number;
      },
      LicensingWorkflowParams | undefined
    >({
      query: (params) => {
        let url = '/licensing-workflows';
        params = { ...params };
        if (['All', 'Expiring Licenses'].includes(params.status ?? '')) {
          delete params.status;
        }
        if (params.status === 'Unassigned') {
          params.assignedAnalyst = 'unassigned';
          params.assignedSpecialist = 'unassigned';
          delete params.status;
        }
        if (params.noActivitySince) {
          params.noActivitySince = formatDate(
            params.noActivitySince,
            'yyyy/MM/dd',
          );
        }
        if (Boolean(Object.values(params || {}).length)) {
          url += `?${qs.stringify(params, {
            filter: (_, value) => (value ? value : undefined),
          })}`;
        }
        return { url, method: 'get' };
      },
      providesTags: [TAGS_MAP.PROVIDER_LICENSING_WORKFLOWS],
    }),
    fetchLicensingWorkflow: builder.query<LicensingWorkflowResponse, string>({
      query: (id) => {
        let url = `/licensing-workflows/${id}`;
        return { url, method: 'get' };
      },
      transformResponse: (response: LicensingWorkflowResponse) =>
        transformLicensingWorkflowDetails(
          response,
        ) as unknown as LicensingWorkflowResponse,
      providesTags: (_result, _error, id) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_DETAILS, { id }),
    }),
    fetchLicensingWorkflowSSNDetails: builder.query<{ ssn: string }, string>({
      query: (id) => {
        let url = `/licensing-workflows/${id}/provider-ssn`;
        return { url, method: 'get' };
      },
    }),
    fetchLicensingWorkflowAssigneesList: builder.query<
      LicensingWorkflowAssigneesResponse,
      void
    >({
      query: () => ({
        url: '/licensing-workflows/assignees',
        method: 'GET',
        headers: generateAuthHeader(),
      }),
      providesTags: [TAGS_MAP.LICENSING_WORKFLOW_ASSIGNEES],
    }),
    bulkAssignAnalystsAndSpecialistsToLicenseWorkflow: builder.mutation<
      unknown,
      {
        payload: {
          analysts: string[];
          specialists: string[];
          workflowIds: string[];
        };
      }
    >({
      query: ({ payload }) => {
        return {
          url: `/licensing-workflows/bulk-assign`,
          method: 'patch',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, arg) => {
        return [
          TAGS_MAP.LICENSING_WORKFLOW_ASSIGNEES,
          TAGS_MAP.PROVIDER_LICENSING_WORKFLOWS,
          ...arg.payload.workflowIds.map((id: string) => {
            return {
              type: TAGS_MAP.LICENSING_WORKFLOW_DETAILS,
              id,
            };
          }),
        ];
      },
    }),
    createNewLicensingWorkflows: builder.mutation<
      NewLicensingWorflowResponse[],
      unknown
    >({
      query: (data) => {
        return {
          url: '/licensing-workflows',
          method: 'post',
          data: { data },
        };
      },
      invalidatesTags: () => [TAGS_MAP.PROVIDER_LICENSING_WORKFLOWS],
    }),
    downloadLicensingWorkflowsCsv: builder.mutation<unknown, void>({
      query: () => ({
        url: '/licensing-workflows/export',
        method: 'GET',
        headers: generateAuthHeader(),
        // TODO: Params to be added when working on Filters
        // params
      }),
    }),

    fetchExpiringLicenses: builder.query<
      ExpiringLicensesResponse,
      ExpiringLicensesQueryParams
    >({
      query: (params) => {
        return {
          url: '/licensing-workflows/expiring-licenses',
          method: 'get',
          params: pickBy(params),
        };
      },
      merge: (currentCache, newResponse, args) => {
        if (!args.arg.cursor) {
          currentCache.licenses.splice(0, currentCache.licenses.length);
        }
        currentCache.licenses.push(...newResponse.licenses);
        currentCache.cursor = newResponse.cursor;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
    }),

    uploadLicensingWorkflowProviders: builder.mutation<unknown, FormData>({
      query: (formData) => {
        return {
          url: '/licensing-workflows/import-providers',
          method: 'post',
          data: formData,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.PROVIDER,
        TAGS_MAP.PROVIDER_FILE_UPLOADS,
      ],
    }),
    updateLicensingWorkflowDetails: builder.mutation<
      unknown,
      { id: string; payload: { [key: string]: any } }
    >({
      query: ({ id, payload }) => {
        return {
          url: `/licensing-workflows/${id}`,
          method: 'patch',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, { id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_DETAILS, { id }),
    }),
    markLicensingWorkflowAsViewed: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/licensing-workflows/${id}/mark-workflow-as-viewed`,
          method: 'patch',
          data: {},
        };
      },
    }),
    fetchLicensingWorkflowTask: builder.query<
      {
        tasks: Task[];
        count: number;
      },
      { licensingWorkflowId: string }
    >({
      query: ({ licensingWorkflowId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks`,
          method: 'get',
        };
      },
      providesTags: (_result, _error, { licensingWorkflowId: id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_TASKS, { id }),
    }),
    fetchLicensingWorkflowTaskDetails: builder.query<
      Task,
      { licensingWorkflowId: string; taskId: string }
    >({
      query: ({ licensingWorkflowId, taskId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}`,
          method: 'get',
        };
      },
      providesTags: (_result, _error, { taskId: id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL, { id }),
    }),
    markLicensingWorkflowTaskAsViewed: builder.mutation<
      unknown,
      { licensingWorkflowId: string; taskId: string }
    >({
      query: ({ licensingWorkflowId, taskId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}/mark-task-as-viewed`,
          method: 'patch',
          body: {},
        };
      },
      invalidatesTags: (_result, _error, { taskId: id }) => {
        return [
          TAGS_MAP.LICENSING_WORKFLOW_TASKS,
          TAGS_MAP.FETCH_LICENSING_WORKFLOW_DASBOARD,
          ...tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL, { id }),
        ];
      },
    }),
    fetchLicensingWorkflowDashboard: builder.query<
      TasksDashboardOverview,
      void
    >({
      query: () => {
        return {
          url: `/licensing-workflows/dashboard`,
          method: 'get',
        };
      },
      providesTags: [TAGS_MAP.FETCH_LICENSING_WORKFLOW_DASBOARD],
    }),
    fetchAllLicensingWorkflowTasks: builder.query<
      {
        tasks: Task[];
        count: number;
      },
      TasksQueryParams
    >({
      query: (filters) => {
        const params = Object.assign({}, filters);
        if (params.type === 'All') {
          delete params.type;
        }
        const query = new URLSearchParams(
          params as { [key: string]: string },
        ).toString();
        return {
          url: query ? `/tasks?${query}` : `/tasks`,
          method: 'get',
        };
      },
      providesTags: () => [TAGS_MAP.ALL_LICENSING_WORKFLOW_TASKS],
    }),
    createLicensingWorkflowTasks: builder.mutation<unknown, unknown>({
      query: ({ payload }) => {
        return {
          url: '/tasks',
          method: 'post',
          data: payload,
        };
      },
      invalidatesTags: () => [TAGS_MAP.LICENSING_WORKFLOW_TASKS],
    }),
    updateLicensingWorkflowTaskDetails: builder.mutation<
      unknown,
      {
        taskId: string;
        licensingWorkflowId: string;
        payload: { [key: string]: any };
      }
    >({
      query: ({ licensingWorkflowId, taskId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}`,
          method: 'patch',
          data: payload,
        };
      },
      onQueryStarted: (
        { licensingWorkflowId, taskId, payload },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          licensingApi.util.updateQueryData(
            'fetchLicensingWorkflowTask',
            { licensingWorkflowId },
            (draft) => {
              let index = -1;
              draft.tasks.forEach((item, i) => {
                if (item.id === taskId) {
                  index = i;
                }
              });
              if (index !== -1) {
                draft.tasks[index] = {
                  ...draft.tasks[index],
                  ...payload,
                };
              }
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, { taskId: id }) => {
        return [
          TAGS_MAP.LICENSING_WORKFLOW_TASKS,
          TAGS_MAP.FETCH_LICENSING_WORKFLOW_DASBOARD,
          ...tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL, { id }),
        ];
      },
    }),
    uploadLicensingWorkflowTaskAttachment: builder.mutation<
      unknown,
      {
        taskId: string;
        licensingWorkflowId: string;
        payload: FormData;
      }
    >({
      query: ({ licensingWorkflowId, taskId, payload }) => {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}/supporting-documents`,
          method: 'post',
          data: payload,
          headers,
        };
      },
      invalidatesTags: (_result, _error, { taskId: id }) => {
        return [
          TAGS_MAP.LICENSING_WORKFLOW_TASKS,
          ...tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL, { id }),
        ];
      },
    }),
    deleteLicensingWorkflowTaskAttachment: builder.mutation<
      unknown,
      { licensingWorkflowId: string; taskId: string; documentId: string }
    >({
      query: ({ licensingWorkflowId, taskId, documentId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}/supporting-documents/${documentId}`,
          method: 'delete',
        };
      },
      invalidatesTags: (_result, _error, { taskId: id }) => {
        return [
          TAGS_MAP.LICENSING_WORKFLOW_TASKS,
          ...tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL, { id }),
        ];
      },
    }),
    deleteLicensingWorkflowTask: builder.mutation<unknown, unknown>({
      query: ({ licensingWorkflowId, taskId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}`,
          method: 'delete',
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.LICENSING_WORKFLOW_TASKS,
        TAGS_MAP.FETCH_LICENSING_WORKFLOW_DASBOARD,
        TAGS_MAP.ALL_LICENSING_WORKFLOW_TASKS,
      ],
    }),
    createLicensingWorkflowTaskOutreach: builder.mutation<unknown, unknown>({
      query: ({ licensingWorkflowId, taskId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}/outreach`,
          method: 'post',
          data: payload,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.LICENSING_WORKFLOW_TASKS,
        TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL,
      ],
    }),
    createLicensingWorkflowTaskNote: builder.mutation<unknown, unknown>({
      query: ({ licensingWorkflowId, taskId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/tasks/${taskId}/notes`,
          method: 'post',
          data: payload,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.LICENSING_WORKFLOW_TASKS,
        TAGS_MAP.LICENSING_WORKFLOW_TASK_DETAIL,
      ],
    }),
    fetchAllLicensingWorkflowOutreachAttempts: builder.query<
      LicensingWorkflowOutreachResponse,
      { licensingWorkflowId: string }
    >({
      query: ({ licensingWorkflowId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/outreach`,
          method: 'get',
        };
      },
      providesTags: (_result, _error, { licensingWorkflowId: id }) => {
        return tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_OUTREACH, { id });
      },
    }),
    createLicensingWorkflowOutreachAttempt: builder.mutation<
      unknown,
      { licensingWorkflowId: string; payload: any }
    >({
      query: ({ licensingWorkflowId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/outreach`,
          method: 'post',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) => {
        return tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_OUTREACH, { id });
      },
    }),
    updateLicensingWorkflowOutreachNote: builder.mutation<
      unknown,
      { licensingWorkflowId: string; outreachId: string; payload: any }
    >({
      query: ({ licensingWorkflowId, outreachId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/outreach/${outreachId}`,
          method: 'put',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) => {
        return tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_OUTREACH, { id });
      },
    }),
    deleteLicensingWorkflowOutreachNote: builder.mutation<
      unknown,
      { licensingWorkflowId: string; outreachId: string }
    >({
      query: ({ licensingWorkflowId, outreachId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/outreach/${outreachId}`,
          method: 'delete',
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) => {
        return tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_OUTREACH, { id });
      },
    }),
    createLicensingWorkflowNote: builder.mutation<
      unknown,
      { licensingWorkflowId: string; payload: { message: string } }
    >({
      query: ({ licensingWorkflowId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/notes`,
          method: 'post',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_DETAILS, { id }),
    }),
    updateLicensingWorkflowNote: builder.mutation<
      unknown,
      {
        licensingWorkflowId: string;
        noteId: string;
        payload: { message: string };
      }
    >({
      query: ({ licensingWorkflowId, noteId, payload }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/notes/${noteId}`,
          method: 'patch',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_DETAILS, { id }),
    }),
    deleteLicensingWorkflowNote: builder.mutation<
      unknown,
      { licensingWorkflowId: string; noteId: string }
    >({
      query: ({ licensingWorkflowId, noteId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/notes/${noteId}`,
          method: 'delete',
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_DETAILS, { id }),
    }),
    updateLicensingWorkflowStatus: builder.mutation<
      unknown,
      {
        licensingWorkflowId: string;
        step: string;
        metadata?: Record<string, string>;
      }
    >({
      query: ({ licensingWorkflowId, step, metadata }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/steps/${step}`,
          method: 'put',
          data: {
            ...metadata,
          },
        };
      },
      invalidatesTags: (_result, _error, { licensingWorkflowId: id }) =>
        tagsWithId(TAGS_MAP.LICENSING_WORKFLOW_DETAILS, { id }),
    }),
    fetchLicensingWorkflowLoginPassword: builder.query<
      { data: string },
      { licensingWorkflowId: string; loginId: string }
    >({
      query: ({ licensingWorkflowId, loginId }) => {
        return {
          url: `/licensing-workflows/${licensingWorkflowId}/logins/${loginId}/password`,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useCreateNewLicensingWorkflowsMutation,
  useFetchLicensingWorkflowsQuery,
  useFetchLicensingWorkflowQuery,
  useDownloadLicensingWorkflowsCsvMutation,
  useUploadLicensingWorkflowProvidersMutation,
  useFetchExpiringLicensesQuery,
  useUpdateLicensingWorkflowDetailsMutation,
  useFetchLicensingWorkflowAssigneesListQuery,
  useBulkAssignAnalystsAndSpecialistsToLicenseWorkflowMutation,
  useFetchLicensingWorkflowTaskQuery,
  useFetchAllLicensingWorkflowTasksQuery,
  useLazyFetchAllLicensingWorkflowTasksQuery,
  useFetchLicensingWorkflowTaskDetailsQuery,
  useCreateLicensingWorkflowTasksMutation,
  useUpdateLicensingWorkflowTaskDetailsMutation,
  useUploadLicensingWorkflowTaskAttachmentMutation,
  useDeleteLicensingWorkflowTaskAttachmentMutation,
  useDeleteLicensingWorkflowTaskMutation,
  useCreateLicensingWorkflowTaskOutreachMutation,
  useCreateLicensingWorkflowTaskNoteMutation,
  useFetchLicensingWorkflowDashboardQuery,
  useMarkLicensingWorkflowAsViewedMutation,
  useMarkLicensingWorkflowTaskAsViewedMutation,
  useFetchAllLicensingWorkflowOutreachAttemptsQuery,
  useCreateLicensingWorkflowOutreachAttemptMutation,
  useUpdateLicensingWorkflowOutreachNoteMutation,
  useDeleteLicensingWorkflowOutreachNoteMutation,
  useUpdateLicensingWorkflowStatusMutation,
  useLazyFetchLicensingWorkflowSSNDetailsQuery,
  useLazyFetchLicensingWorkflowLoginPasswordQuery,
  useCreateLicensingWorkflowNoteMutation,
  useUpdateLicensingWorkflowNoteMutation,
  useDeleteLicensingWorkflowNoteMutation,
  useLazyFetchLicensingWorkflowQuery,
} = licensingApi;
