import { combineReducers, AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { authReducer, AuthState } from './Auth/Auth';
import { loggedInUserReducer, LoggedInUserState } from './loggedInUserReducer';
import {
  signupRequestsReducer,
  SignupRequestsState,
} from './signupRequestsReducer';
import {
  credentialingWorkflowStepsReducer,
  CredentialingWorkflowStepsState,
} from './credentialingWorkflowStepsReducer';
import {
  organizationMemberReducer,
  OrganizationMembershipState,
} from './organizationMembershipReducer';
import {
  orgSecretsReducer,
  OrgSecretsState,
} from './organizationSecretsReducer';
import { helpBannersReducer, HelpBannerState } from './helpBannersReducer';
import {
  MonitoringWorkflowListState,
  monitoringWorkflowsReducer,
} from './monitoringWorkflowsReducer';
import {
  organizationSupportingDocumentsReducer,
  OrganizationSupportingDocumentsState,
} from './organizationSupportingDocumentsReducer';
import { clientApi } from '../../services';
import { applicationApi } from '../../services/application-api.service';
import { exportApi } from '../../services/export-api.service';

export default combineReducers({
  auth: authReducer,
  signupRequests: signupRequestsReducer,
  loggedInUser: loggedInUserReducer,
  credentialingWorkflowSteps: credentialingWorkflowStepsReducer,
  organizationMember: organizationMemberReducer,
  orgSecrets: orgSecretsReducer,
  helpBanners: helpBannersReducer,
  monitoringWorkflows: monitoringWorkflowsReducer,
  organizationSupportingDocuments: organizationSupportingDocumentsReducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [exportApi.reducerPath]: exportApi.reducer,
});

export interface AppState {
  auth: AuthState;
  signupRequests: SignupRequestsState;
  loggedInUser: LoggedInUserState;
  credentialingWorkflowSteps: CredentialingWorkflowStepsState;
  organizationMember: OrganizationMembershipState;
  orgSecrets: OrgSecretsState;
  helpBanners: HelpBannerState;
  monitoringWorkflows: MonitoringWorkflowListState;
  organizationSupportingDocuments: OrganizationSupportingDocumentsState;
}

export type AppDispatch = ThunkDispatch<AppState, any, AnyAction>;
