import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react';
import { pick } from 'lodash';
import {
  Box,
  styled,
  Drawer,
  Toolbar,
  Stack,
  SvgIconProps,
} from '@mui/material';
import { useRouter } from 'next/router';
import { IconButton, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '#/redux/reducers/rootReducer';
import { updateSelectedOrg } from '#/redux/actions/Auth/Auth';
import {
  CREDENTIALING_USER_ROLE,
  MEDICAL_DIRECTOR_ROLE,
  ORGANIZATION_ADMIN_ROLE,
  PLATFORM_USER_ROLE,
  PROVISIONAL_APPROVER_ROLE,
  BUSINESS_ADMIN_ROLE,
  ENTITY_MANAGER,
  LICENSING_ADMIN_ROLE,
  LICENSING_ANALYST_ROLE,
  LICENSING_SPECIALIST_ROLE,
  LICENSING_TEAM_LEAD_ROLE,
  PE_LEAD_ROLE,
  PE_ANALYST_ROLE,
  PE_CLIENT_ADMIN,
  VIEWER_ROLE,
} from '#/Utils/Constants';
import { TreeItem, TreeView } from '#/components/design-system/tree/TreeView';
import {
  DoctorIcon,
  CredCommitteeIcon,
  CredentialingIcon,
  LicensingIcon,
  ContractingIcon,
  MonitoringIcon,
} from '#/components/design-system/icons';

import {
  DashboardTwoTone as DashboardIcon,
  CorporateFareTwoTone as CorporateFareIcon,
  Menu as MenuIcon,
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  ReceiptLong as ReceiptLongIcon,
  WorkHistory as WorkHistoryIcon,
  FlagTwoTone,
  PeopleOutlineTwoTone,
} from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  Selector,
  SelectorDirection,
} from '#/components/design-system/selector/Selector';
import { useFetchUserByIdQuery } from '#/services';
import { AccessPermissionsType, SideBarTabType, UserRoleValue } from '#/types';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

const TABS = {
  DASHBOARD: 'Dashboard',
  ORG_ENTITIES: 'Org Entities',
  PROVIDERS: 'Providers',
  PAYOR_ENROLLMENT: 'Payor Enrollment',
  FACILITIES: 'Facilities',
  CREDENTIALING: 'Credentialing',
  FACILITIES_CREDENTIALING: 'Facility Credentialing',
  MONITORING: 'Monitoring',
  FLAGS: 'Flags',
  COMMITTEE_PLATFORM: 'Committee Platform',
  LICENSING: 'Licensing',
  TASKS_DASHBOARD: 'Tasks Dashboard',
  CONTRACTING: 'Contracting',
  SUPPORTING_DOCS: 'Supporting Docs',
  PROVIDER_UPLOADS: 'Provider Uploads',
  ORGANIZATIONS: 'Organizations',
  ROSTEROS: 'RosterOS',
} as const;

const TAB_KEYS: (keyof typeof TABS)[] = [
  'DASHBOARD',
  'TASKS_DASHBOARD',
  'ORG_ENTITIES',
  'PROVIDERS',
  'PAYOR_ENROLLMENT',
  'FACILITIES',
  'CREDENTIALING',
  'FACILITIES_CREDENTIALING',
  'MONITORING',
  'FLAGS',
  'COMMITTEE_PLATFORM',
  'LICENSING',
  'CONTRACTING',
  'SUPPORTING_DOCS',
  'PROVIDER_UPLOADS',
  'ORGANIZATIONS',
  'ROSTEROS',
];
type TabKey = (typeof TAB_KEYS)[number];
const TAB_ROUTE_MAP: Record<TabKey | 'USER_SETTINGS', string[]> = {
  ORG_ENTITIES: ['/org-entities', '/org-entities/[id]'],
  ROSTEROS: ['/rosteros'],
  PROVIDERS: ['/providers', '/providers/[id]', '/provider-uploads'],
  PAYOR_ENROLLMENT: ['/payor-enrollments', '/payor-enrollments/[id]'],
  FACILITIES: ['/facilities', '/facilities/[id]'],
  CREDENTIALING: ['/credentialing', '/credentialing/[id]'],
  FACILITIES_CREDENTIALING: [
    '/facility-credentialing-workflows',
    '/facility-credentialing-workflows/[id]',
  ],
  PROVIDER_UPLOADS: ['/provider-file-uploads'],
  COMMITTEE_PLATFORM: ['/committee-platform'],
  LICENSING: ['/licensing'],
  TASKS_DASHBOARD: ['/tasks-dashboard'],
  CONTRACTING: ['/contracting'],
  SUPPORTING_DOCS: ['/supporting-documents'],
  USER_SETTINGS: ['/settings'],
  MONITORING: ['/monitoring-workflows'],
  ORGANIZATIONS: ['/organizations', '/organizations/[id]'],
  FLAGS: ['/flags'],
  DASHBOARD: ['/'],
};
const ACCESS_PERMISSION_TAB: SideBarTabType = {
  orgEntities: 'ORG_ENTITIES',
  rosterOs: 'ROSTEROS',
  providers: 'PROVIDERS',
  payorEnrollment: 'PAYOR_ENROLLMENT',
  licensing: 'LICENSING',
  tasksDashboard: 'TASKS_DASHBOARD',
  committee: 'COMMITTEE_PLATFORM',
  contracting: 'CONTRACTING',
  credentialing: 'CREDENTIALING',
  facilities: 'FACILITIES',
  facilityCredentialing: 'FACILITIES_CREDENTIALING',
  supportingDocs: 'SUPPORTING_DOCS',
  providerUploads: 'PROVIDER_UPLOADS',
  monitoring: 'MONITORING',
  flags: 'FLAGS',
};
const iconStyles = { fontSize: '20px' };
interface TabIconMap {
  [key: string]: React.FC<SvgIconProps>;
}
const TAB_ICONS: TabIconMap = {
  DASHBOARD: (props) => <DashboardIcon style={iconStyles} {...props} />,
  TASKS_DASHBOARD: (props) => <TaskAltIcon style={iconStyles} {...props} />,
  ORG_ENTITIES: (props) => <DoctorIcon style={iconStyles} {...props} />,
  PROVIDERS: (props) => <DoctorIcon style={iconStyles} {...props} />,
  PAYOR_ENROLLMENT: (props) => (
    <PeopleOutlineTwoTone style={iconStyles} {...props} />
  ),
  FACILITIES: (props) => <DoctorIcon style={iconStyles} {...props} />,
  CREDENTIALING: (props) => <CredentialingIcon style={iconStyles} {...props} />,
  FACILITIES_CREDENTIALING: (props) => (
    <CredentialingIcon style={iconStyles} {...props} />
  ),
  COMMITTEE_PLATFORM: (props) => (
    <CredCommitteeIcon style={iconStyles} {...props} />
  ),
  LICENSING: (props) => <LicensingIcon style={iconStyles} {...props} />,
  CONTRACTING: (props) => <ContractingIcon style={iconStyles} {...props} />,
  MONITORING: (props) => <MonitoringIcon style={iconStyles} {...props} />,
  SUPPORTING_DOCS: (props) => <ReceiptLongIcon style={iconStyles} {...props} />,
  PROVIDER_UPLOADS: (props) => (
    <WorkHistoryIcon style={iconStyles} {...props} />
  ),
  ORGANIZATIONS: (props) => <ContractingIcon style={iconStyles} {...props} />,
  ROSTEROS: (props) => <DoctorIcon style={iconStyles} {...props} />,
  FLAGS: (props) => <FlagTwoTone style={iconStyles} {...props} />,
};
const TAB_ROLES: Record<
  (typeof TAB_KEYS)[number],
  Array<UserRoleValue | 'All'>
> = {
  DASHBOARD: ['All'],
  PAYOR_ENROLLMENT: [PE_LEAD_ROLE, PE_ANALYST_ROLE, PE_CLIENT_ADMIN],
  ORG_ENTITIES: [ENTITY_MANAGER],
  ROSTEROS: ['All'],
  FLAGS: ['All'],
  PROVIDERS: [
    PLATFORM_USER_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  FACILITIES: [
    PLATFORM_USER_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  FACILITIES_CREDENTIALING: [
    PLATFORM_USER_ROLE,
    MEDICAL_DIRECTOR_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  CREDENTIALING: [
    PLATFORM_USER_ROLE,
    MEDICAL_DIRECTOR_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  COMMITTEE_PLATFORM: [MEDICAL_DIRECTOR_ROLE, CREDENTIALING_USER_ROLE],
  LICENSING: [
    CREDENTIALING_USER_ROLE,
    LICENSING_ADMIN_ROLE,
    LICENSING_ANALYST_ROLE,
    LICENSING_SPECIALIST_ROLE,
    LICENSING_TEAM_LEAD_ROLE,
  ],
  TASKS_DASHBOARD: [
    VIEWER_ROLE,
    LICENSING_ANALYST_ROLE,
    LICENSING_SPECIALIST_ROLE,
    LICENSING_TEAM_LEAD_ROLE,
    PE_LEAD_ROLE,
    PE_ANALYST_ROLE,
    PE_CLIENT_ADMIN,
  ],
  CONTRACTING: [CREDENTIALING_USER_ROLE],
  MONITORING: [CREDENTIALING_USER_ROLE, PLATFORM_USER_ROLE],
  SUPPORTING_DOCS: [ORGANIZATION_ADMIN_ROLE],
  PROVIDER_UPLOADS: [
    CREDENTIALING_USER_ROLE,
    PLATFORM_USER_ROLE,
    MEDICAL_DIRECTOR_ROLE,
  ],
  ORGANIZATIONS: [BUSINESS_ADMIN_ROLE],
};

const TreeViewContent = styled(Box)`
  overflow: scroll;
  height: calc(100vh - 220px);
`;
const Footer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2.5),
  width: `calc(100% - ${theme.spacing(4)})`,
}));
const collapseArrowIconStyles = { left: '-6px', position: 'absolute' };
const OrgButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.main,
  },
  width: '2em',
  height: '2em',
}));

export const SidebarRdb = () => {
  const router = useRouter();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedTabId, setSelectedTabId] = useState<string | undefined>(
    TABS.DASHBOARD,
  );
  const [navCollapsed, setNavCollapsed] = useState(false);
  const {
    enrollments: showEnrollmentsFlag,
    payor_enrollment: enablePayorEnrollment,
    rosteros: showRosterOsFlag,
    flags_dashboard: showFlagsDashboard,
    enable_licensing_workflows: enableLicensingWorkflows,
  } = useFeatureFlags();

  const dispatch = useDispatch<AppDispatch>();

  const { data: userInfo, isLoading } = useFetchUserByIdQuery('me');

  const tabRefs: MutableRefObject<{ [id: string]: HTMLAnchorElement }> = useRef(
    {},
  );
  const tabTreeRef: MutableRefObject<HTMLDivElement | undefined> = useRef();
  const selectedOrgId = useSelector(
    (state: AppState) => state.loggedInUser.selectedOrgId,
  );
  const orgs = useMemo(() => {
    const items = (userInfo?.toReturn.memberships || []).map((mem) => ({
      key: mem.organizationId,
      value: mem.organizationName,
    }));
    return items.sort((a, b) => a.value.localeCompare(b.value));
  }, [userInfo?.toReturn?.memberships]);
  const selectedOrg = useMemo(
    () => orgs.find((org) => org.key === selectedOrgId),
    [orgs, selectedOrgId],
  );
  const tabs: Record<string, string> = useMemo(() => {
    const currentOrganization = userInfo?.toReturn.memberships.find(
      ({ organizationId }) => organizationId === selectedOrgId,
    );

    if (!currentOrganization) return {};
    const tabsToShow: string[] = ['DASHBOARD'];

    const accessPermissionsKeys = Object.keys(ACCESS_PERMISSION_TAB);

    const enabledPermissions: string[] = accessPermissionsKeys.filter(
      (perm: string) =>
        !!currentOrganization?.accessPermissions?.[
          perm as keyof AccessPermissionsType
        ],
    );
    const enabledTabs = enabledPermissions.map(
      (permission: string) =>
        ACCESS_PERMISSION_TAB[permission as keyof AccessPermissionsType],
    );

    if (showEnrollmentsFlag || enablePayorEnrollment) {
      tabsToShow.push('PAYOR_ENROLLMENT');
    }

    if (enableLicensingWorkflows || enablePayorEnrollment) {
      tabsToShow.push('TASKS_DASHBOARD');
    }

    if (!!currentOrganization?.airtable?.airtableUrl) {
      tabsToShow.push('LICENSING');
    }

    if (
      !!currentOrganization?.organizationName
        ?.toLowerCase()
        .includes('certifyos')
    ) {
      tabsToShow.push('ORGANIZATIONS');
    }

    tabsToShow.push(...enabledTabs);

    if (showRosterOsFlag) {
      // Add ROSTEROS until we setup permissions
      tabsToShow.push('ROSTEROS');
    }

    if (showFlagsDashboard) {
      tabsToShow.push('FLAGS');
    }
    return pick(TABS, tabsToShow);
  }, [
    userInfo?.toReturn.memberships,
    showRosterOsFlag,
    showEnrollmentsFlag,
    showFlagsDashboard,
    selectedOrgId,
    enableLicensingWorkflows,
  ]);

  const drawerWidth = useMemo(() => (navCollapsed ? 88 : 220), [navCollapsed]);
  const drawerWidthLg = useMemo(
    () => (navCollapsed ? 88 : 280),
    [navCollapsed],
  );

  const setTabRef = useCallback(
    (tab: string) => (el: HTMLAnchorElement) => {
      tabRefs.current[tab] = el;
    },
    [],
  );

  useEffect(() => {
    // when route changes, update the selected tab id
    if (router && router.pathname) {
      const [tabName] =
        Object.entries(TAB_ROUTE_MAP).find(([_, route]) => {
          if (Array.isArray(route)) {
            return route.some((r) => router.pathname === r);
          }
          return router.pathname.includes(route as string);
        }) || [];
      const tabId = tabs[(tabName as TabKey) || ''];
      if (
        tabTreeRef?.current &&
        tabTreeRef?.current?.scrollTo &&
        tabName &&
        tabRefs.current[tabName]
      ) {
        const top =
          tabRefs.current[tabName].offsetTop - tabTreeRef.current.offsetTop;
        tabTreeRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
      setSelectedTabId(tabId);
    }
  }, [router, tabs]);

  return (
    <Drawer
      elevation={0}
      variant="permanent"
      sx={(theme) => ({
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
          width: drawerWidthLg,
        },
      })}
      PaperProps={{
        sx: (theme) => ({
          width: drawerWidth,
          [theme.breakpoints.up('md')]: {
            width: drawerWidthLg,
          },
          boxSizing: 'border-box',
          padding: theme.spacing(2),
        }),
      }}
    >
      <Toolbar />
      <TreeView selected={selectedTabId} expanded={expanded}>
        <TreeViewContent ref={tabTreeRef} role="TreeViewContent">
          {TAB_KEYS.map((tab) => {
            const roles = userInfo?.roles || [];
            const matchedRoles = TAB_ROLES[tab].filter(
              (role) => role === 'All' || roles.includes(role),
            );
            if (isLoading) {
              return (
                <Skeleton
                  key={tab}
                  sx={{ bgcolor: 'grey.100' }}
                  animation="wave"
                  height={60}
                />
              );
            }
            if (matchedRoles.length === 0) return;

            const Icon = TAB_ICONS[tab];
            return (
              tabs[tab] && (
                <a key={tab} ref={setTabRef(tab)} href={TAB_ROUTE_MAP[tab][0]}>
                  <TreeItem
                    nodeId={tabs[tab as TabKey]}
                    label={navCollapsed ? '' : tabs[tab]}
                    icon={
                      <Icon
                        color={
                          selectedTabId === tabs[tab] ? 'primary' : 'inherit'
                        }
                      />
                    }
                  />
                </a>
              )
            );
          })}
        </TreeViewContent>
      </TreeView>
      <Footer sx={{ background: '#fff' }}>
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <IconButton
            onClick={() => {
              setExpanded([]);
              setTimeout(() => {
                setNavCollapsed(!navCollapsed);
              }, 200);
            }}
            sx={{ ml: (theme) => theme.spacing(1) }}
          >
            {navCollapsed ? (
              <ArrowRightIcon fontSize="small" sx={collapseArrowIconStyles} />
            ) : (
              <ArrowLeftIcon fontSize="small" sx={collapseArrowIconStyles} />
            )}
            <MenuIcon />
          </IconButton>
          {!navCollapsed && (
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.text.surface.disabled }}
            >
              Collapse menu
            </Typography>
          )}
        </Stack>
        {navCollapsed ? (
          <OrgButton sx={{ ml: (theme) => theme.spacing(0.4) }}>
            <Typography variant="body1" sx={{ color: 'white' }}>
              {selectedOrg && selectedOrg?.value[0]}
            </Typography>
          </OrgButton>
        ) : (
          <Box onClick={() => setNavCollapsed(false)}>
            <Selector
              placeholder="Select Organization"
              searchInputPlaceholder="Search by name"
              direction={SelectorDirection.top}
              defaultIcon={<CorporateFareIcon />}
              items={orgs}
              value={selectedOrg}
              onChange={(item) => {
                dispatch(updateSelectedOrg(item.key));
                return null;
              }}
            />
          </Box>
        )}
      </Footer>
    </Drawer>
  );
};
