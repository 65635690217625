import { IconButton } from '#/components/design-system/icon-button/IconButton';
import {
  useFetchNotificationsQuery,
  useLazyFetchCredWorkflowStepQuery,
} from '#/services';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { Notification } from '#/types';
import { Badge } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { NotificationDrawer } from '../NotificationDrawer';
import { NotificationActions } from '../NotificationActions';
import { useIsWindowFocused } from '../../hooks/useIsWindowFocused';

export const Notifications: React.FC = () => {
  const router = useRouter();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const isWindowFocused = useIsWindowFocused();
  const { data: items } = useFetchNotificationsQuery(undefined, {
    pollingInterval: isWindowFocused ? 30000 : 0,
    refetchOnFocus: true,
  });
  const [fetchCredWorkflowSteps] = useLazyFetchCredWorkflowStepQuery();

  useEffect(() => {
    // refetch the credentialing workflow if psv generation notification received
    const workflowId: string = router?.query?.id as string;
    const notifs = items?.data || [];
    const psvGenNotification = notifs.find(
      (item) =>
        item &&
        item.metadata?.credentialingWorkflowId === workflowId &&
        item.type === 'psvGeneration',
    );
    if (
      workflowId &&
      router?.route.includes('/credentialing/') &&
      psvGenNotification
    ) {
      fetchCredWorkflowSteps(workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query, items]);

  return (
    <>
      <IconButton
        aria-label="notifications-button"
        selected={notificationsOpen}
        onClick={() => setNotificationsOpen(!notificationsOpen)}
      >
        <Badge badgeContent={items?.count || 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <NotificationDrawer
        open={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
        items={items?.data!}
        renderActions={(
          item: Notification,
          { markAsRead }: { markAsRead: () => void },
        ) => {
          return (
            <NotificationActions
              notification={item}
              onRead={() => {
                markAsRead();
              }}
            />
          );
        }}
      />
    </>
  );
};
