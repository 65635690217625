import { applicationApi } from './application-api.service';
import {
  FacilityFormData,
  FetchFacilitiesListApiResponse,
} from '#/api/facilities';
import { AddNewFacilityPayload } from '#/types';
import { transformFetchFacilityByIdResponse } from '#/data-transformers/facility.transformer';
import {
  FacilityDetails,
  FetchFacilityByIdResponse,
  UpdateFacilityPayload,
  UpdateFacilityStatusPayload,
} from '#/types/facility.types';
import { TAGS_MAP } from './tags/tags';
import { pickBy } from 'lodash';

export const facilitiesApi = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchFacilities: builder.query<
      FetchFacilitiesListApiResponse,
      FacilityFormData | void
    >({
      query: (formData: FacilityFormData) => ({
        url: '/facilities',
        method: 'get',
        data: null,
        params: pickBy(formData!),
      }),
    }),
    createNewFacility: builder.mutation<unknown, AddNewFacilityPayload>({
      query: (payload: AddNewFacilityPayload) => ({
        url: '/facilities',
        method: 'post',
        data: {
          ...payload,
        },
      }),
    }),
    updateFacility: builder.mutation<unknown, UpdateFacilityPayload>({
      query: ({ facilityId, body }: UpdateFacilityPayload) => {
        return {
          url: `/facilities/${facilityId}`,
          method: 'patch',
          data: {
            ...body,
          },
        };
      },
      invalidatesTags: [
        TAGS_MAP.FACILITY,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
      ],
    }),
    updateFacilityStatus: builder.mutation<
      unknown,
      UpdateFacilityStatusPayload
    >({
      query: ({ facilityId, body }: UpdateFacilityStatusPayload) => {
        return {
          url: `/facilities/${facilityId}/status`,
          method: 'patch',
          data: {
            ...body,
          },
        };
      },
    }),
    uploadSupportingDocument: builder.mutation<
      unknown,
      { facilityId: string; formData: FormData }
    >({
      query: ({ facilityId, formData }) => {
        return {
          url: `/facilities/${facilityId}/supporting-document`,
          method: 'post',
          data: formData,
        };
      },
    }),
    deleteSupportingDocument: builder.mutation<
      unknown,
      { facilityId: string; documentId: string }
    >({
      query: ({ facilityId, documentId }) => {
        return {
          url: `/facilities/${facilityId}/supporting-document/${documentId}`,
          method: 'delete',
        };
      },
      invalidatesTags: [
        TAGS_MAP.FACILITY,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
      ],
    }),
    fetchFacility: builder.query<FacilityDetails, string>({
      query: (facilityId) => ({
        url: `/facilities/${facilityId}`,
        method: 'get',
        data: null,
      }),
      transformResponse: (response: FetchFacilityByIdResponse) => {
        return transformFetchFacilityByIdResponse(response);
      },
      providesTags: [TAGS_MAP.FACILITY],
    }),
  }),
});

export const {
  useFetchFacilitiesQuery,
  useCreateNewFacilityMutation,
  useFetchFacilityQuery,
  useUpdateFacilityMutation,
  useUpdateFacilityStatusMutation,
  useDeleteSupportingDocumentMutation,
  useUploadSupportingDocumentMutation,
} = facilitiesApi;
