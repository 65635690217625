export const TAGS_MAP = {
  ORGANIZATION_DEPENDENT: 'ORGANIZATION_DEPENDENT',
  ORGANIZATIONS: 'ORGANIZATIONS',
  ORGANIZATIONS_MEMBERSHIPS: 'ORGANIZATIONS_MEMBERSHIPS',
  MONITORING_WORKFLOW: 'MONITORING_WORKFLOW',
  MONITORING_WORKFLOW_LIST: 'MONITORING_WORKFLOW_LIST',
  MONITORING_ANALYST: 'MONITORING_ANALYST',
  CREDENTIALING_WORKFLOW: 'CREDENTIALING_WORKFLOW',
  CREDENTIALING_WORKFLOW_STATS: 'CREDENTIALING_WORKFLOW_STATS',
  CREDENTIALING_WORKFLOW_List: 'CREDENTIALING_WORKFLOW_List',
  CREDENTIALING_COMMITTEE: 'CREDENTIALING_COMMITTEE',
  MONITORING_OUTREACH_CORRESPONDENCE: 'MONITORING_OUTREACH_CORRESPONDENCE',
  CREDENTIALING_OUTREACH_CORRESPONDENCE:
    'CREDENTIALING_OUTREACH_CORRESPONDENCE',
  OUTREACH_CORRESPONDENCE: 'OUTREACH_CORRESPONDENCE',
  FACILITY_OUTREACH_CORRESPONDENCE: 'FACILITY_OUTREACH_CORRESPONDENCE',
  CREDENTIAL_ANALYST: 'CREDENTIAL_ANALYST',
  CREDENTIAL_MEDICAL_DIRECTORS: 'CREDENTIAL_MEDICAL_DIRECTORS',
  MY_TEAMS_CREDENTIAL_ANALYST: 'MY_TEAMS_CREDENTIAL_ANALYST',
  FACILITY_ANALYST: 'FACILITY_ANALYST',
  PROVIDER_FILE_UPLOADS: 'PROVIDER_FILE_UPLOADS',
  PROVIDERS_FOR_MONITORING: 'PROVIDERS_FOR_MONITORING',
  FACILITY: 'FACILITY',
  FACILITY_CRED_WORKFLOW_LIST: 'FACILITY_CRED_WORKFLOW_LIST',
  FACILITY_CRED_WORKFLOW_DETAIL: 'FACILITY_CRED_WORKFLOW_DETAIL',
  PROVIDER_DETAILS: 'PROVIDER_DETAILS',
  PROVIDER: 'PROVIDER',
  EDIT_PROVIDER: 'EDIT_PROVIDER',
  ROLES: 'ROLES',
  NOTIFICATIONS: 'NOTIFICATIONS',
  USER: 'USER',
  LOGGED_IN_USER: 'LOGGED_IN_USER',
  LICENSING_WORKFLOW: 'LICENSING_WORKFLOW',
  ADMIN_ORG_MEMBERSHIPS: 'ADMIN_ORG_MEMBERSHIPS',
  ORGANIZATION_SECRETS: 'ORGANIZATION_SECRETS',
  PIPELINE_EXECUTIONS: 'PIPELINE_EXECUTIONS',
  SURVEY_CONFIGS: 'SURVEY_CONFIGS',
  SURVEY_QUESTION: 'SURVEY_QUESTION',
  ORG_ENTITIES: 'ORG_ENTITIES',
  ORG_ENTITY_DETAILS: 'ORG_ENTITY_DETAILS',
  ORG_ENTITY_ENROLLMENTS: 'ORG_ENTITY_ENROLLMENTS',
  ORG_ENTITY_ENROLLMENT: 'ORG_ENTITY_ENROLLMENT',
  ORG_ENTITY_ENROLLMENT_PROVIDERS: 'ORG_ENTITY_ENROLLMENT_PROVIDERS',
  ORG_ENTITY_LOCATIONS: 'ORG_ENTITY_LOCATIONS',
  ORG_ENTITY_PROVIDERS: 'ORG_ENTITY_PROVIDERS',
  ORG_ENTITY_DOCUMENTS: 'ORG_ENTITY_DOCUMENTS',
  LOCATION_TAGS: 'LOCATION_TAGS',
  ROSTERS: 'ROSTERS',
  ROSTERS_ERRORS: 'ROSTERS_ERRORS',
  ROSTERS_USERS: 'ROSTERS_USERS',
  PROVIDER_LICENSING_WORKFLOWS: 'PROVIDER_LICENSING_WORKFLOWS',
  LICENSING_WORKFLOW_ASSIGNEES: 'LICENSING_WORKFLOW_ASSIGNEES',
  FLAGS: 'FLAGS',
  PE_WORKFLOWS: 'PE_WORKFLOWS',
  PE_WORKFLOWS_ASSIGNEES: 'PE_WORKFLOWS_ASSIGNEES',
  PE_WORKFLOW: 'PE_WORKFLOW',
  PE_WORKFLOW_MISSING_INFO: 'PE_WORKFLOW_MISSING_INFO',
  PE_WORKFLOW_ACTIVITY_LOG: 'PE_WORKFLOW_ACTIVITY_LOG',
  PE_WORKFLOW_OUTREACH_ATTEMPTS: 'PE_WORKFLOW_OUTREACH_ATTEMPTS',
  MONITORED_PROVIDERS: 'MONITORED_PROVIDERS',
  LICENSING_WORKFLOW_DETAILS: 'LICENSING_WORKFLOW_DETAILS',
  LICENSING_WORKFLOW_TASKS: 'LICENSING_WORKFLOW_TASKS',
  LICENSING_WORKFLOW_TASK_DETAIL: 'LICENSING_WORKFLOW_TASK_DETAIL',
  ALL_LICENSING_WORKFLOW_TASKS: 'ALL_LICENSING_WORKFLOW_TASKS',
  FETCH_LICENSING_WORKFLOW_DASBOARD: 'FETCH_LICENSING_WORKFLOW_DASBOARD',
  LICENSING_WORKFLOW_OUTREACH: 'LICENSING_WORKFLOW_OUTREACH',
  PE_WORKFLOW_OUTREACH: 'PE_WORKFLOW_OUTREACH',
  STATE_LICENSES: 'STATE_LICENSES',
  DEA_DATA: 'DEA_DATA',
  BOARD_CERTIFICATIONS: 'BOARD_CERTIFICATIONS',
  MALPRACTICE_INSURANCE: 'MALPRACTICE_INSURANCE',
  APPLICATION_VERIFICATIONS: 'APPLICATION_VERIFICATIONS',
  NPDB: 'NPDB',
  TEAMS_WORK_CREDENTIALING_LIST: 'TEAMS_WORK_CREDENTIALING_LIST',
  TEAMS_WORK_PROVIDERS_LIST: 'TEAMS_WORK_PROVIDERS_LIST',
  MY_WORK_LIST: 'MY_WORK_LIST',
  SUPPORTING_DOCUMENTS: 'SUPPORTING_DOCUMENTS',
  MISSING_INFORMATION_FIELDS_CONFIG: 'MISSING_INFORMATION_FIELDS_CONFIG',
  TASKS: 'TASKS',
  TASK_DETAIL: 'TASK_DETAIL',
  FETCH_TASK_DASBOARD: 'FETCH_TASK_DASBOARD',
  PAYORS: 'PAYORS',
  ALL_PLANS: 'ALL_PLANS',
  PAYOR_PLANS: 'PAYOR_PLANS',
  TASK_ASSIGNEE_LIST: 'TASK_ASSIGNEE_LIST',
} as const;

export type TAG = keyof typeof TAGS_MAP;

export const TAGS = Object.keys(TAGS_MAP) as TAG[];

export const ORGANIZATION_INDEPENDENT_TAGS: TAG[] = [TAGS_MAP.USER];
export const ORGANIZATION_DEPENDENT_TAGS: TAG[] = TAGS.filter(
  (tag) => !ORGANIZATION_INDEPENDENT_TAGS.includes(tag),
);
