import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import { find, pick } from 'lodash';
import {
  Box,
  styled,
  Drawer,
  Toolbar,
  Stack,
  SvgIconProps,
} from '@mui/material';
import { useRouter } from 'next/router';
import { IconButton, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '#/redux/reducers/rootReducer';
import { updateSelectedOrg } from '#/redux/actions/Auth/Auth';
import {
  CREDENTIALING_USER_ROLE,
  MEDICAL_DIRECTOR_ROLE,
  ORGANIZATION_ADMIN_ROLE,
  PLATFORM_USER_ROLE,
  PROVISIONAL_APPROVER_ROLE,
  BUSINESS_ADMIN_ROLE,
  LICENSING_ADMIN_ROLE,
  LICENSING_ANALYST_ROLE,
  LICENSING_SPECIALIST_ROLE,
  LICENSING_TEAM_LEAD_ROLE,
  ENTITY_MANAGER,
  VIEWER_ROLE,
  allOrgsValue,
  allOrgs,
  SUPERVISOR_ROLE,
  ANALYST_ROLE,
  PE_LEAD_ROLE,
  PE_CLIENT_ADMIN,
  PE_ANALYST_ROLE,
} from '#/Utils/Constants';

import { TreeItem, TreeView } from '#/components/design-system/tree/TreeView';
import {
  DoctorIcon,
  CredCommitteeIcon,
  CredentialingIcon,
  LicensingIcon,
  ContractingIcon,
  MonitoringIcon,
} from '#/components/design-system/icons';

import {
  DashboardTwoTone as DashboardIcon,
  CorporateFareTwoTone as CorporateFareIcon,
  Menu as MenuIcon,
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  ReceiptLong as ReceiptLongIcon,
  WorkHistory as WorkHistoryIcon,
  FlagTwoTone,
  Groups3,
  PeopleAltOutlined,
  PeopleOutlineTwoTone,
} from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  OrgSelectorItem,
  Selector,
  SelectorDirection,
} from '#/components/design-system/selector/Selector';
import { useFetchUserByIdQuery } from '#/services';
import { AccessPermissionsType, Membership, SideBarTabType } from '#/types';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

const TABS = {
  DASHBOARD: 'Dashboard',
  PAYOR_ENROLLMENT: 'Payor Enrollment',
  ORG_ENTITIES: 'Org Entities',
  PROVIDERS: 'Providers',
  FACILITIES: 'Facilities',
  CREDENTIALING: 'Credentialing',
  FACILITIES_CREDENTIALING: 'Facility Credentialing',
  MONITORING: 'Monitoring',
  FLAGS: 'Flags',
  COMMITTEE_PLATFORM: 'Committee Platform',
  LICENSING: 'Licensing',
  TASKS_DASHBOARD: 'Tasks Dashboard',
  CONTRACTING: 'Contracting',
  SUPPORTING_DOCS: 'Supporting Docs',
  PROVIDER_UPLOADS: 'Provider Uploads',
  ORGANIZATIONS: 'Organizations',
  ROSTEROS: 'RosterOS',
} as const;

const ALL_ORG_TABS = {
  ALL_ORG_DASHBOARD: 'Dashboard',
  MY_TEAMS_WORK: `My Team's Work`,
  MY_WORK: 'My Work',
} as const;

const TAB_KEYS: (keyof typeof TABS)[] = [
  'DASHBOARD',
  'TASKS_DASHBOARD',
  'PAYOR_ENROLLMENT',
  'ORG_ENTITIES',
  'PROVIDERS',
  'FACILITIES',
  'CREDENTIALING',
  'FACILITIES_CREDENTIALING',
  'MONITORING',
  'FLAGS',
  'COMMITTEE_PLATFORM',
  'LICENSING',
  'CONTRACTING',
  'SUPPORTING_DOCS',
  'PROVIDER_UPLOADS',
  'ORGANIZATIONS',
  'ROSTEROS',
];

const ALL_ORG_TAB_KEYS: (keyof typeof ALL_ORG_TABS)[] = [
  'ALL_ORG_DASHBOARD',
  'MY_TEAMS_WORK',
  'MY_WORK',
];
type TabKey = (typeof TAB_KEYS)[number];
const TAB_ROUTE_MAP: any = {
  ORG_ENTITIES: ['/org-entities', '/org-entities/[id]'],
  ROSTEROS: ['/rosteros'],
  PROVIDERS: ['/providers', '/providers/[id]', '/provider-uploads'],
  PAYOR_ENROLLMENT: ['/payor-enrollments', '/payor-enrollments/[id]'],
  FACILITIES: ['/facilities', '/facilities/[id]'],
  CREDENTIALING: ['/credentialing', '/credentialing/[id]'],
  FACILITIES_CREDENTIALING: [
    '/facility-credentialing-workflows',
    '/facility-credentialing-workflows/[id]',
  ],
  PROVIDER_UPLOADS: ['/provider-file-uploads'],
  COMMITTEE_PLATFORM: '/committee-platform',
  LICENSING: '/licensing',
  TASKS_DASHBOARD: ['/tasks-dashboard'],
  CONTRACTING: '/contracting',
  SUPPORTING_DOCS: '/supporting-documents',
  USER_SETTINGS: '/settings',
  MONITORING: '/monitoring-workflows',
  ORGANIZATIONS: ['/organizations', '/organizations/[id]'],
  FLAGS: '/flags',
  MY_TEAMS_WORK: '/my-teams-work',
  ALL_ORG_DASHBOARD: '/all-org-dashboard',
  MY_WORK: '/my-work',
  // dashboard should stay as last element of the array since it matches for all routes
  DASHBOARD: '/',
};
const ACCESS_PERMISSION_TAB: SideBarTabType = {
  orgEntities: 'ORG_ENTITIES',
  rosterOs: 'ROSTEROS',
  providers: 'PROVIDERS',
  payorEnrollment: 'PAYOR_ENROLLMENT',
  licensing: 'LICENSING',
  tasksDashboard: 'TASKS_DASHBOARD',
  committee: 'COMMITTEE_PLATFORM',
  contracting: 'CONTRACTING',
  credentialing: 'CREDENTIALING',
  facilities: 'FACILITIES',
  facilityCredentialing: 'FACILITIES_CREDENTIALING',
  supportingDocs: 'SUPPORTING_DOCS',
  providerUploads: 'PROVIDER_UPLOADS',
  monitoring: 'MONITORING',
  flags: 'FLAGS',
};
const iconStyles = { fontSize: '20px' };
interface TabIconMap {
  [key: string]: React.FC<SvgIconProps>;
}
const TAB_ICONS: TabIconMap = {
  DASHBOARD: (props) => <DashboardIcon style={iconStyles} {...props} />,
  TASKS_DASHBOARD: (props) => <TaskAltIcon style={iconStyles} {...props} />,
  PAYOR_ENROLLMENT: (props) => (
    <PeopleOutlineTwoTone style={iconStyles} {...props} />
  ),
  ORG_ENTITIES: (props) => <DoctorIcon style={iconStyles} {...props} />,
  PROVIDERS: (props) => <DoctorIcon style={iconStyles} {...props} />,
  FACILITIES: (props) => <DoctorIcon style={iconStyles} {...props} />,
  CREDENTIALING: (props) => <CredentialingIcon style={iconStyles} {...props} />,
  FACILITIES_CREDENTIALING: (props) => (
    <CredentialingIcon style={iconStyles} {...props} />
  ),
  COMMITTEE_PLATFORM: (props) => (
    <CredCommitteeIcon style={iconStyles} {...props} />
  ),
  LICENSING: (props) => <LicensingIcon style={iconStyles} {...props} />,
  CONTRACTING: (props) => <ContractingIcon style={iconStyles} {...props} />,
  MONITORING: (props) => <MonitoringIcon style={iconStyles} {...props} />,
  SUPPORTING_DOCS: (props) => <ReceiptLongIcon style={iconStyles} {...props} />,
  PROVIDER_UPLOADS: (props) => (
    <WorkHistoryIcon style={iconStyles} {...props} />
  ),
  ORGANIZATIONS: (props) => <ContractingIcon style={iconStyles} {...props} />,
  ROSTEROS: (props) => <DoctorIcon style={iconStyles} {...props} />,
  FLAGS: (props) => <FlagTwoTone style={iconStyles} {...props} />,
  ALL_ORG_DASHBOARD: (props) => <DashboardIcon style={iconStyles} {...props} />,
  MY_TEAMS_WORK: (props) => <Groups3 style={iconStyles} {...props} />,
  MY_WORK: (props) => <PeopleAltOutlined style={iconStyles} {...props} />,
};
const TAB_ROLES: Record<
  (typeof TAB_KEYS | typeof ALL_ORG_TAB_KEYS)[number],
  string[]
> = {
  DASHBOARD: ['All'],
  PAYOR_ENROLLMENT: [PE_LEAD_ROLE, PE_ANALYST_ROLE, PE_CLIENT_ADMIN],
  ORG_ENTITIES: [ENTITY_MANAGER],
  ROSTEROS: ['All'],
  FLAGS: ['All'],
  PROVIDERS: [
    VIEWER_ROLE,
    PLATFORM_USER_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  FACILITIES: [
    PLATFORM_USER_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  FACILITIES_CREDENTIALING: [
    PLATFORM_USER_ROLE,
    MEDICAL_DIRECTOR_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  CREDENTIALING: [
    VIEWER_ROLE,
    PLATFORM_USER_ROLE,
    MEDICAL_DIRECTOR_ROLE,
    CREDENTIALING_USER_ROLE,
    PROVISIONAL_APPROVER_ROLE,
  ],
  COMMITTEE_PLATFORM: [
    MEDICAL_DIRECTOR_ROLE,
    CREDENTIALING_USER_ROLE,
    VIEWER_ROLE,
  ],
  LICENSING: [
    VIEWER_ROLE,
    CREDENTIALING_USER_ROLE,
    LICENSING_ADMIN_ROLE,
    LICENSING_ANALYST_ROLE,
    LICENSING_SPECIALIST_ROLE,
    LICENSING_TEAM_LEAD_ROLE,
  ],
  TASKS_DASHBOARD: [
    VIEWER_ROLE,
    LICENSING_ANALYST_ROLE,
    LICENSING_SPECIALIST_ROLE,
    LICENSING_TEAM_LEAD_ROLE,
    PE_LEAD_ROLE,
    PE_ANALYST_ROLE,
    PE_CLIENT_ADMIN,
  ],
  CONTRACTING: [CREDENTIALING_USER_ROLE],
  MONITORING: [CREDENTIALING_USER_ROLE, PLATFORM_USER_ROLE],
  SUPPORTING_DOCS: [ORGANIZATION_ADMIN_ROLE],
  PROVIDER_UPLOADS: [
    CREDENTIALING_USER_ROLE,
    PLATFORM_USER_ROLE,
    MEDICAL_DIRECTOR_ROLE,
  ],
  ORGANIZATIONS: [BUSINESS_ADMIN_ROLE],
  MY_TEAMS_WORK: [SUPERVISOR_ROLE],
  ALL_ORG_DASHBOARD: ['All'],
  MY_WORK: [ANALYST_ROLE],
};

const TreeViewContent = styled(Box)`
  overflow: scroll;
  height: calc(100vh - 220px);
`;
const Footer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2.5),
  width: `calc(100% - ${theme.spacing(4)})`,
}));
const collapseArrowIconStyles = { left: '-6px', position: 'absolute' };
const OrgButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.main,
  },
  width: '2em',
  height: '2em',
}));

export const SidebarFS = () => {
  const router = useRouter();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedTabId, setSelectedTabId] = useState<string | undefined>(
    TABS.DASHBOARD,
  );
  const [navCollapsed, setNavCollapsed] = useState(false);
  const {
    enrollments: showEnrollmentsFlag,
    payor_enrollment: enablePayorEnrollment,
    rosteros: showRosterOsFlag,
    flags_dashboard: showFlagsDashboard,
    enable_licensing_workflows: enableLicensingWorkflows,
    all_org_pages: allOrgPages,
  } = useFeatureFlags();

  const dispatch = useDispatch<AppDispatch>();

  const { data: userInfo } = useFetchUserByIdQuery('me');

  const loggedInUser = useSelector((state: AppState) => state.loggedInUser);
  const tabRefs: MutableRefObject<{ [id: string]: HTMLDivElement }> = useRef(
    {},
  );
  const tabTreeRef: MutableRefObject<HTMLDivElement | undefined> = useRef();

  const tabs: Record<string, string> = useMemo(() => {
    const selectedOrg: Membership | undefined = find(
      userInfo?.toReturn?.memberships,
      {
        organizationId: loggedInUser.selectedOrgId,
      },
    );

    if (!selectedOrg && loggedInUser.selectedOrgId !== allOrgsValue) return {};
    if (loggedInUser.selectedOrgId === allOrgsValue) {
      const tabsToShow: string[] = ['ALL_ORG_DASHBOARD'];
      tabsToShow.push('MY_TEAMS_WORK');
      tabsToShow.push('MY_WORK');
      return pick(ALL_ORG_TABS, tabsToShow);
    } else {
      const tabsToShow: string[] = ['DASHBOARD'];

      const accessPermissionsKeys = Object.keys(ACCESS_PERMISSION_TAB);

      const enabledPermissions: string[] = accessPermissionsKeys.filter(
        (perm: string) =>
          !!selectedOrg?.accessPermissions?.[
            perm as keyof AccessPermissionsType
          ],
      );

      const enabledTabs = enabledPermissions.map(
        (permission: string) =>
          ACCESS_PERMISSION_TAB[permission as keyof AccessPermissionsType],
      );

      if (enableLicensingWorkflows || enablePayorEnrollment) {
        tabsToShow.push('TASKS_DASHBOARD');
      }

      if (!!selectedOrg?.airtable?.airtableUrl) {
        tabsToShow.push('LICENSING');
      }
      if (
        !!selectedOrg?.organizationName?.toLowerCase().includes('certifyos')
      ) {
        tabsToShow.push('ORGANIZATIONS');
      }

      tabsToShow.push(...enabledTabs);

      if (showRosterOsFlag) {
        // Add ROSTEROS until we setup permissions
        tabsToShow.push('ROSTEROS');
      }
      if (showEnrollmentsFlag || enablePayorEnrollment) {
        tabsToShow.push('PAYOR_ENROLLMENT');
      }

      if (showFlagsDashboard) {
        tabsToShow.push('FLAGS');
      }

      return pick(TABS, tabsToShow);
    }
  }, [
    userInfo?.toReturn?.memberships,
    loggedInUser.selectedOrgId,
    showRosterOsFlag,
    showEnrollmentsFlag,
    showFlagsDashboard,
    enablePayorEnrollment,
    enableLicensingWorkflows,
  ]);

  const drawerWidth = navCollapsed ? 88 : 220;
  const drawerWidthLg = navCollapsed ? 88 : 280;

  const orgs = (loggedInUser.data?.memberships || []).map((mem) => ({
    key: mem.organizationId,
    value: mem.organizationName,
  }));

  const selectedOrg = useMemo(
    () =>
      [...orgs, allOrgs].find((org) => org.key === loggedInUser.selectedOrgId),
    [loggedInUser],
  );

  useEffect(() => {
    // when route changes, update the selected tab id
    if (router && router.pathname) {
      const [tabName] =
        Object.entries(TAB_ROUTE_MAP).find(([_, route]) => {
          if (Array.isArray(route)) {
            return route.some((r) => router.pathname === r);
          }
          return router.pathname.includes(route as string);
        }) || [];
      const tabId = tabs[(tabName as TabKey) || ''];
      if (
        tabTreeRef?.current &&
        tabTreeRef?.current?.scrollTo &&
        tabName &&
        tabRefs.current[tabName]
      ) {
        const top =
          tabRefs.current[tabName].offsetTop - tabTreeRef.current.offsetTop;
        tabTreeRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
      setSelectedTabId(tabId);
    }
  }, [router, tabs]);

  const onChangeOrganization = (item: OrgSelectorItem) => {
    dispatch(updateSelectedOrg(item.key));
    if (allOrgPages) {
      redirectToDefaultTabByOrg(item.key);
    }
    return null;
  };

  const redirectToDefaultTabByOrg = (key: string) => {
    const tabName =
      Object.entries(TAB_ROUTE_MAP).find(([_, route]) => {
        if (Array.isArray(route)) {
          return route.find((r) => router.pathname === r);
        }
        return router.pathname.includes(route as string);
      }) || [];

    if (tabName.length > 0 && tabName[0]) {
      if (
        key === allOrgsValue &&
        !Object.keys(ALL_ORG_TABS).find((x) => x == tabName[0])
      ) {
        router.push(TAB_ROUTE_MAP.ALL_ORG_DASHBOARD);
      } else if (
        key !== allOrgsValue &&
        !Object.keys(TABS).find((x) => x == tabName[0])
      ) {
        router.push(TAB_ROUTE_MAP.DASHBOARD);
      }
    }
  };

  const onTabSelect = (e: React.SyntheticEvent, nodeId: string) => {
    setSelectedTabId(nodeId);
  };

  return (
    <Drawer
      elevation={0}
      variant="permanent"
      sx={(theme) => ({
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
          width: drawerWidthLg,
        },
      })}
      PaperProps={{
        sx: (theme) => ({
          width: drawerWidth,
          [theme.breakpoints.up('md')]: {
            width: drawerWidthLg,
          },
          boxSizing: 'border-box',
          padding: theme.spacing(2),
        }),
      }}
    >
      <Toolbar />
      <TreeView
        selected={selectedTabId}
        onNodeSelect={onTabSelect}
        expanded={expanded}
      >
        <TreeViewContent
          ref={(el: HTMLDivElement) => {
            tabTreeRef.current = el;
          }}
          role="TreeViewContent"
        >
          {(loggedInUser.selectedOrgId === allOrgsValue
            ? ALL_ORG_TAB_KEYS
            : TAB_KEYS
          ).map((tab) => {
            const roles = loggedInUser.data?.roles || [];
            const matchedRoles = TAB_ROLES[tab].filter(
              (role: string) => roles.includes(role) || role === 'All',
            );
            if (!loggedInUser.data) {
              return (
                <Skeleton
                  key={tab}
                  sx={{ bgcolor: 'grey.100' }}
                  animation="wave"
                  height={60}
                />
              );
            }
            if (matchedRoles.length === 0) return <></>;
            const ref = (element: HTMLDivElement) => {
              tabRefs.current[tab] = element;
            };
            const Icon = TAB_ICONS[tab];
            return (
              tabs[tab] && (
                <div key={tab} ref={ref}>
                  <TreeItem
                    nodeId={tabs[tab as TabKey]}
                    label={navCollapsed ? '' : tabs[tab]}
                    icon={
                      <Icon
                        color={
                          selectedTabId === tabs[tab] ? 'primary' : 'inherit'
                        }
                      />
                    }
                    onClick={() => {
                      if (Array.isArray(TAB_ROUTE_MAP[tab])) {
                        router.push(TAB_ROUTE_MAP[tab][0]);
                      } else {
                        router.push(TAB_ROUTE_MAP[tab]);
                      }
                    }}
                  />
                </div>
              )
            );
          })}
        </TreeViewContent>
      </TreeView>
      <Footer sx={{ background: '#fff' }}>
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <IconButton
            onClick={() => {
              setExpanded([]);
              setTimeout(() => {
                setNavCollapsed(!navCollapsed);
              }, 200);
            }}
            sx={{ ml: (theme) => theme.spacing(1) }}
          >
            {navCollapsed ? (
              <ArrowRightIcon fontSize="small" sx={collapseArrowIconStyles} />
            ) : (
              <ArrowLeftIcon fontSize="small" sx={collapseArrowIconStyles} />
            )}
            <MenuIcon />
          </IconButton>
          {!navCollapsed && (
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.text.surface.disabled }}
            >
              Collapse menu
            </Typography>
          )}
        </Stack>
        {navCollapsed ? (
          <OrgButton sx={{ ml: (theme) => theme.spacing(0.4) }}>
            <Typography variant="body1" sx={{ color: 'white' }}>
              {selectedOrg && selectedOrg?.value[0]}
            </Typography>
          </OrgButton>
        ) : (
          <Box onClick={() => setNavCollapsed(false)}>
            <Selector
              placeholder="Select Organization"
              searchInputPlaceholder="Search by name"
              direction={SelectorDirection.top}
              defaultIcon={<CorporateFareIcon />}
              items={orgs}
              value={selectedOrg}
              onChange={onChangeOrganization}
            />
          </Box>
        )}
      </Footer>
    </Drawer>
  );
};
